import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule} from '@angular/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {MatSortModule} from '@angular/material/sort';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from "@angular/material/select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatChipsModule} from "@angular/material/chips";
import {MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatRipple} from '@angular/material/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDialogModule} from '@angular/material/dialog';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

// PRODUCTION
import {DtrCatalogComponent} from "./dtr-catalog/dtr-catalog.component";
import {DtrAasComponent} from "./dtr-aas/dtr-aas.component";
import {DigitalTwinComponent} from './digital-twin/digital-twin.component';
import {SharedItemComponent} from './shared-item/shared-item.component';
import {SharedProductComponent} from './shared-product/shared-product.component';
import {SharedItemNewComponent} from './shared-item-new/shared-item-new.component';

// TEST
import {DigitalTwinTestComponent} from './digital-twin-test/digital-twin-test.component';
import {DigitalTwinTestNewComponent} from './digital-twin-test-new/digital-twin-test-new.component';
import {BatchComponent} from './aspects/batch/batch.component';
import {BatchNewComponent} from './aspects/batch-new/batch-new.component';
import {PartTypeInformationComponent} from './aspects/part-type-information/part-type-information.component';
import {PartTypeInformationNewComponent} from './aspects/part-type-information-new/part-type-information-new.component';
import {LookupDataModelComponent} from './lookup-data-model/lookup-data-model.component';
import {QualityNotificationComponent} from './notifications/quality-notification/quality-notification.component';
import {
  UniqueIdPushNotificationComponent
} from "./notifications/uniqueidpush-notification/uniqueidpush-notification.component";
import {
  AuthenticationInterceptorService
} from './core/services/authentication-interceptor/authentication-interceptor.service';
import {AuthenticationService} from './core/services/authentication/authentication.service';
import {NavComponent} from './shared/nav/nav.component';
import {ExpansionPanelComponent} from './shared/expansion-panel/expansion-panel.component';
import {ProgressSpinnerComponent} from './shared/progress-spinner/progress-spinner.component';
import {CardLayoutComponent} from './shared/card-layout/card-layout.component';
import {TableComponent} from './shared/table/table.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatStepperModule} from "@angular/material/stepper";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatSidenavModule} from "@angular/material/sidenav";
import {EdcCatalogComponent} from "./edc-catalog/edc-catalog.component";
import {EdcContractsComponent} from "./edc-contracts/edc-contracts.component";
import {NgOptimizedImage} from "@angular/common";
import {EdcTransfersComponent} from "./edc-transfers/edc-transfers.component";
import {EdcAssetDataComponent} from "./edc-asset-data/edc-asset-data.component";
import {MatAutocomplete, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {Router} from '@angular/router';
import {LoginComponent} from "./core/auth/login/login.component";
import {LandingPageComponent} from "./landing-page/landing-page.component";
import {FaqComponent} from "./faq/faq.component";
import {MatGridList, MatGridTile} from "@angular/material/grid-list";
import {NotAuthorizedComponent} from './core/auth/not-authorized/not-authorized.component';
import {PageComponent} from './core/auth/page.component';
import {LogoutComponent} from './core/auth/logout/logout.component';

import {SelectComponent} from './shared/select/select.component';
import {CustomTooltipComponent} from "./shared/custom-tooltip/custom-tooltip.component";
import {FooterComponent} from './shared/footer/footer.component';
import {HeaderComponent} from './shared/header/header.component';
import {TopMenuComponent} from './shared/top-menu/top-menu.component';
import {ImprintComponent} from './shared/footer/imprint/imprint.component';

export function appInitFactory(
  injector: Injector
): any {
  return () => checkLogIn(injector);
}

export function checkLogIn(
  injector: Injector
) {
  const router = injector.get(Router);
  const authenticationService = injector.get(AuthenticationService);
  return new Promise((resolve) => {
    if (authenticationService.isAuthenticated()) {
      authenticationService.createUser();
    } else {
      const code = authenticationService.getQueryParameterByName('code');

      if (code) {
        router.navigate(['login'], {queryParams: {code}});
      }

    }
    resolve(true);
  });
}

@NgModule({
  declarations: [
    CustomTooltipComponent,
    SelectComponent,
    AppComponent,
    LoginComponent,
    LogoutComponent,
    NotAuthorizedComponent,
    LandingPageComponent,
    FaqComponent,
    FooterComponent,
    HeaderComponent,
    TopMenuComponent,
    QualityNotificationComponent,
    UniqueIdPushNotificationComponent,
    SharedItemComponent,
    SharedProductComponent,
    SharedItemNewComponent,
    LookupDataModelComponent,
    DtrCatalogComponent,
    DtrAasComponent,
    EdcCatalogComponent,
    EdcAssetDataComponent,
    EdcContractsComponent,
    EdcTransfersComponent,
    NavComponent,
    ProgressSpinnerComponent,
    CardLayoutComponent,
    TableComponent,
    ExpansionPanelComponent,
    DtrCatalogComponent,
    DigitalTwinComponent,
    DigitalTwinTestComponent,
    DigitalTwinTestNewComponent,
    BatchComponent,
    BatchNewComponent,
    PartTypeInformationComponent,
    PartTypeInformationNewComponent,
    PageComponent,
    ImprintComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,
    MatListModule,
    MatCardModule,
    HttpClientModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatRadioModule,
    MatIconModule,
    MatTooltipModule,
    ClipboardModule,
    MatButtonModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatStepperModule,
    MatToolbarModule,
    MatSidenavModule,
    NgOptimizedImage,
    MatAutocompleteTrigger,
    MatAutocomplete,
    MatRipple,
    MatGridList,
    MatGridTile
  ],
  providers: [
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptorService,
      multi: true
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: {color: 'primary'},
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitFactory,
      deps: [Injector],
      multi: true,
    },
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {subscriptSizing: 'dynamic'}}
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
