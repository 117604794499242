/** As every IDP has defined its own user data in the token, you can map it to the titanium user object */
export interface UserMapping {
  orgCode?: string;
  mail?: string;
  givenName?: string;
  id?: string;
  fullName?: string;
  surname?: string;
  groups?: string;
}

/** Default values typical for Federation Service */
export const DEFAULT_USER_MAPPING: UserMapping = {
  orgCode: 'OrgCode',
  mail: 'mail',
  givenName: 'givenName',
  id: 'cn',
  fullName: 'fullName',
  surname: 'sn',
  groups: 'CFDSGroupMembership'
}
