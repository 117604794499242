import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { DigitalTwin } from '../shared/models/digital-twin.dto';

import { MatPaginator } from "@angular/material/paginator";
import { HelpersService } from "../shared/services/helpers.service";

@Component({
  selector: 'app-digital-twin',
  templateUrl: './digital-twin.component.html',
  styleUrls: ['./digital-twin.component.css']
})
export class DigitalTwinComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  cards: any[] = [];
  allCards: any[] = [];
  pagedItems: any[] = [];
  searchTerm: string = '';
  creationDate: string
  modificationDate: string
  userName: string
  breadcrumb: any = [{ title: 'Automatically created digital twins', href: '' }];
  dataLoaded = false;

  toggleState: string = 'All';
  filterType: string = 'All';

  constructor(private http: HttpClient, public dialog: MatDialog) { }

  ngOnInit() {
    this.http.get<any>("/api/v1/digitaltwin/production").pipe(
      map(res => res.map((x: DigitalTwin) => ({
        id: x.id,
        cxIdentification: x.cxIdentification,
        cxGlobalAssetId: x.cxGlobalAssetId,
        bpn: x.bpn,
        materialNumberBASF: x.materialNumberBASF,
        customerNumberList: HelpersService.getCustomerPropertyListString(x.customerList, 'customerMaterialNumber'),
        customerNameList: HelpersService.getCustomerPropertyListString(x.customerList, 'customerName'),
        customerBPNList: HelpersService.getCustomerPropertyListString(x.customerList, 'customerBpn'),
        batchNumber: x.batchNumber,
        isRegistered: x.isRegistered,
        digitalTwinType: x.digitalTwinType
      })))
    ).subscribe(
      response => {
        this.allCards = response;
        HelpersService.applyFilters(this.cards,
          this.allCards,
          this.filterType,
          this.toggleState,
          this.searchTerm,
          this.paginator,
          (filteredCards: any[]) => {
            this.cards = filteredCards;
            this.updatePagedItems();
          }
        );

        this.dataLoaded = true;
      }
    );
  }

  onStateChange(event: string) {
    this.toggleState = event;
    HelpersService.applyFilters(this.cards,
      this.allCards,
      this.filterType,
      this.toggleState,
      this.searchTerm,
      this.paginator,
      (filteredCards: any[]) => {
        this.cards = filteredCards;
        this.updatePagedItems();
        this.paginator.firstPage();
      }
    );
  }

  onTypeChange(event: string) {
    this.filterType = event;
    HelpersService.applyFilters(this.cards,
      this.allCards,
      this.filterType,
      this.toggleState,
      this.searchTerm,
      this.paginator,
      (filteredCards: any[]) => {
        this.cards = filteredCards;
        this.updatePagedItems();
        this.paginator.firstPage();
      }
    );
  }

  applySearch() {
    HelpersService.applyFilters(this.cards,
      this.allCards,
      this.filterType,
      this.toggleState,
      this.searchTerm,
      this.paginator,
      (filteredCards: any[]) => {
        this.cards = filteredCards;
        this.updatePagedItems();
        this.paginator.firstPage();
      }
    );
  }

  pageChanged() {
    this.updatePagedItems();
  }

  updatePagedItems() {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    this.pagedItems = this.cards.slice(startIndex, startIndex + this.paginator.pageSize);
  }

  getComponentHistory(id: string) {
    HelpersService.getComponentHistory(id, true, this.http, this.dialog);
  }

  protected readonly HelpersService = HelpersService;
}
