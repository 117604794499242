<app-card-layout [title]="'Unique ID Push Notifications'">
  <app-table [dataSource]="dataSource" [dataLoaded]="dataLoaded">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="messageId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by messageId">
          Message ID
        </th>
        <td mat-cell *matCellDef="let element" class="text-field"> {{ element.messageId }}</td>
      </ng-container>

      <ng-container matColumnDef="digitalTwinType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by digitalTwinType"> Digital
          Twin Type
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.digitalTwinType }}</td>
      </ng-container>

      <ng-container matColumnDef="senderBpn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by senderBpn">Sender BPN</th>
        <td mat-cell *matCellDef="let element" class="text-field"> {{ element.senderBpn }}</td>
      </ng-container>

      <ng-container matColumnDef="sentDateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by sentDateTime"> Sent Date
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.sentDateTime }}</td>
      </ng-container>

      <ng-container matColumnDef="expectedResponseBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by expectedResponseBy">
          Expected Response By
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.expectedResponseBy }}</td>
      </ng-container>

      <ng-container matColumnDef="isConnected">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by isConnected">
          Is Connected
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="{{'isRegistered-'+element.isConnected}}">{{element.isConnected}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button [disabled]="dialogOpen"
                  (click)="openUniqueIdPushNotificationPopup(element.messageId)"
                  matTooltip="View">
            <mat-icon color="primary">visibility</mat-icon>
          </button>
          <button mat-icon-button [matMenuTriggerFor]="mainmenu" matTooltip="Menu">
            <mat-icon color="primary">more_vert</mat-icon>
          </button>
          <mat-menu #mainmenu="matMenu">
            <button mat-menu-item *ngIf="!element.isConnected" (click)="connectToChild(element.messageId)">
              <mat-icon color="primary">sync</mat-icon>
              Connect to Child
            </button>
            <button mat-menu-item (click)="openDeleteConfirmationPopup(element.messageId)">
              <mat-icon color="warn">delete</mat-icon>
              Delete
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </app-table>
</app-card-layout>

