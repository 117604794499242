import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SharedItemComponent} from './shared-item/shared-item.component';
import {SharedProductComponent} from './shared-product/shared-product.component';
import {SharedItemNewComponent} from './shared-item-new/shared-item-new.component';
import {LookupDataModelComponent} from './lookup-data-model/lookup-data-model.component';
import {AuthGuard} from './core/services/guards/auth/auth.guard';
import {AdminAuthGuard} from './core/services/guards/auth/adminAuth.guard';
import {DigitalTwinComponent} from "./digital-twin/digital-twin.component";

// TEST
import {DigitalTwinTestComponent} from "./digital-twin-test/digital-twin-test.component";
import {DigitalTwinTestNewComponent} from "./digital-twin-test-new/digital-twin-test-new.component";
import {QualityNotificationComponent} from './notifications/quality-notification/quality-notification.component';
import {
  UniqueIdPushNotificationComponent
} from './notifications/uniqueidpush-notification/uniqueidpush-notification.component';
import {BatchComponent} from './aspects/batch/batch.component';
import {BatchNewComponent} from './aspects/batch-new/batch-new.component';
import {PartTypeInformationComponent} from './aspects/part-type-information/part-type-information.component';
import {PartTypeInformationNewComponent} from './aspects/part-type-information-new/part-type-information-new.component';
import {EdcCatalogComponent} from './edc-catalog/edc-catalog.component';
import {EdcAssetDataComponent} from "./edc-asset-data/edc-asset-data.component";
import {EdcContractsComponent} from "./edc-contracts/edc-contracts.component";
import {EdcTransfersComponent} from "./edc-transfers/edc-transfers.component";
import {LoginComponent} from "./core/auth/login/login.component";
import {LandingPageComponent} from "./landing-page/landing-page.component";
import {FaqComponent} from './faq/faq.component';
import {BlankLayoutComponent} from './layouts/blank-layout/blank-layout.component';
import {NotAuthorizedComponent} from './core/auth/not-authorized/not-authorized.component';
import {LogoutComponent} from './core/auth/logout/logout.component';
import {ImprintComponent} from './shared/footer/imprint/imprint.component';
import {DtrCatalogComponent} from "./dtr-catalog/dtr-catalog.component";
import {DtrAasComponent} from "./dtr-aas/dtr-aas.component";

const routes: Routes = [
  {path: '', component: LandingPageComponent},
  {path: 'landing', component: LandingPageComponent},
  {path: 'faq', component: FaqComponent},
  {path: 'imprint', component: ImprintComponent},
  {path: 'quality-notifications', canActivate: [AdminAuthGuard], component: QualityNotificationComponent},
  {path: 'uniqueidpush-notifications', canActivate: [AdminAuthGuard], component: UniqueIdPushNotificationComponent},
  {path: 'shared-item', canActivate: [AuthGuard], component: SharedItemComponent},
  {path: 'shared-product/:sharedItemId', canActivate: [AuthGuard], component: SharedProductComponent},
  {path: 'shared-items-new', canActivate: [AuthGuard], component: SharedItemNewComponent},
  {path: 'lookup-data-model', canActivate: [AuthGuard], component: LookupDataModelComponent},
  {path: 'dtr-catalog', canActivate: [AdminAuthGuard], component: DtrCatalogComponent},
  {path: 'dtr-aas/:id', canActivate: [AdminAuthGuard], component: DtrAasComponent},
  {path: 'edc-catalog', canActivate: [AdminAuthGuard], component: EdcCatalogComponent},
  {path: 'edc-asset-data', canActivate: [AdminAuthGuard], component: EdcAssetDataComponent},
  {path: 'edc-contracts', canActivate: [AdminAuthGuard], component: EdcContractsComponent},
  {path: 'edc-transfers', canActivate: [AdminAuthGuard], component: EdcTransfersComponent},
  {path: 'digital-twin', canActivate: [AuthGuard], component: DigitalTwinComponent},
  {path: 'digital-twin-test', canActivate: [AuthGuard], component: DigitalTwinTestComponent},
  {path: 'digital-twin-test/new', canActivate: [AuthGuard], component: DigitalTwinTestNewComponent},
  {path: 'digital-twin-test/new/:id', canActivate: [AuthGuard], component: DigitalTwinTestNewComponent},
  {path: 'batch', canActivate: [AuthGuard], component: BatchComponent},
  {path: 'batch/new', canActivate: [AuthGuard], component: BatchNewComponent},
  {path: 'batch/new/:idBatch', canActivate: [AuthGuard], component: BatchNewComponent},
  {path: 'part-type-information', canActivate: [AuthGuard], component: PartTypeInformationComponent},
  {path: 'part-type-information/new', canActivate: [AuthGuard], component: PartTypeInformationNewComponent},
  {path: 'part-type-information/new/:idPart', canActivate: [AuthGuard], component: PartTypeInformationNewComponent},
  {
    path: 'login',
    component: BlankLayoutComponent,
    children: [
      {path: '', component: LoginComponent}
    ]
  },
  {
    path: 'not-authorized',
    component: BlankLayoutComponent,
    children: [
      {path: '', component: NotAuthorizedComponent}
    ]
  },
  {
    path: 'logout',
    component: BlankLayoutComponent,
    children: [
      {path: '', component: LogoutComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
