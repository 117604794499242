import {Component, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {MatDialog} from '@angular/material/dialog';
import {MessageService} from '../shared/services/message.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatPaginator} from "@angular/material/paginator";

import {HelpersService} from "../shared/services/helpers.service";
import {AAS} from "../shared/models/dtr/aas.dto";
import {Customer} from "../shared/models/digital-twin.dto";


@Component({
  selector: 'app-dtr-catalog',
  templateUrl: './dtr-catalog.component.html',
  styleUrls: ['./dtr-catalog.component.css']
})
export class DtrCatalogComponent {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  dataLoaded = false;

  showFirstLastButtons = true;

  toggleState: string = 'All';
  filterType: string = 'All';
  cards: any[] = [];
  allCards: any[] = [];
  pagedItems: any[] = [];
  searchTerm: string = '';
  breadcrumb: any = [{title: 'BASF Digital Twin Registry Catalog at Cofinity-X', href: ''}];

  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    public dialog: MatDialog,
    public messageService: MessageService) {
  }

  ngOnInit() {
    this.http.get<any>("/api/v1/dtr").pipe(
      map(res => res.map((x: AAS) => {
        const customerList: Customer[] = x.specificAssetIds
          .filter(id => id.name === 'customerPartId' && id.externalSubjectId?.keys)
          .map(id => {
            const customerBpnKey = id.externalSubjectId.keys.find(key => key.type === 'GlobalReference');
            return {
              bilateralContract: '',
              customerName: '',
              customerMaterialNumber: id.value,
              customerBpn: customerBpnKey ? customerBpnKey.value : ''
            };
          });
        return {
          id: x.id,
          globalAssetId: x.globalAssetId,
          materialNumberBASF: x.specificAssetIds.find(x => x.name === 'manufacturerPartId')?.value,
          bpn: x.specificAssetIds.find(x => x.name === 'manufacturerId')?.value,
          batchNumber: x.specificAssetIds.find(x => x.name === 'batchId')?.value,
          digitalTwinType: x.specificAssetIds.find(x => x.name === 'digitalTwinType')?.value,
          customerNumberList: HelpersService.getCustomerPropertyListString(customerList, 'customerMaterialNumber'),
          customerBPNList: HelpersService.getCustomerPropertyListString(customerList, 'customerBpn'),
        };
      })))
      .subscribe(
        response => {
          this.allCards = response;
          this.dataLoaded = true;
          HelpersService.applyFilters(this.cards,
            this.allCards,
            this.filterType,
            this.toggleState,
            this.searchTerm,
            this.paginator,
            (filteredCards: any[]) => {
              this.cards = filteredCards;
              this.updatePagedItems();
            }
          );
        }
      );

    this.messageService.getMessage().subscribe(message => {
      if (message) {
        this.activateAlert('green-snackbar', message.text);
      }
    });
  }

  activateAlert(type: string, message: string) {
    this.snackBar.open(message, '', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: [type]
    });
  }

  onTypeChange(event: string) {
    this.filterType = event;
    HelpersService.applyFilters(this.cards,
      this.allCards,
      this.filterType,
      this.toggleState,
      this.searchTerm,
      this.paginator,
      (filteredCards: any[]) => {
        this.cards = filteredCards;
        this.updatePagedItems();
        this.paginator.firstPage();
      }
    );
  }

  applySearch() {
    HelpersService.applyFilters(this.cards,
      this.allCards,
      this.filterType,
      this.toggleState,
      this.searchTerm,
      this.paginator,
      (filteredCards: any[]) => {
        this.cards = filteredCards;
        this.updatePagedItems();
        this.paginator.firstPage();
      }
    );
  }

  updatePagedItems() {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    this.pagedItems = this.cards.slice(startIndex, startIndex + this.paginator.pageSize);
  }

  pageChanged() {
    this.updatePagedItems();
  }

  protected readonly HelpersService = HelpersService;
}
