<app-card-layout [title]="'Transfer Processes'" [breadcrumb]="breadcrumb">

  <app-table [dataSource]="dataSource" [dataLoaded]="dataLoaded">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by id">ID</th>
        <td mat-cell *matCellDef="let element">
          {{ element.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by state">State</th>
        <td mat-cell *matCellDef="let element">{{ element.state }}</td>
      </ng-container>

      <ng-container matColumnDef="stateTimestamp">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by stateTimestamp">State
          Timestamp
        </th>
        <td mat-cell *matCellDef="let element">{{ element.stateTimestamp }}</td>
      </ng-container>

      <ng-container matColumnDef="edcType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by edcType">
          Type
        </th>
        <td mat-cell *matCellDef="let element">{{ element.edcType }}</td>
      </ng-container>

      <ng-container matColumnDef="assetId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by assetId">Asset ID</th>
        <td mat-cell *matCellDef="let element" class="no-overflow">{{ element.assetId }}</td>
      </ng-container>

      <ng-container matColumnDef="contractId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by contractId">Contract ID
        </th>
        <td mat-cell *matCellDef="let element" class="no-overflow">{{ element.contractId }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </app-table>

</app-card-layout>
