import {UserMapping} from './user-mapping';

/** Representing the logged in user. The fields are coming from the access token. */
export interface FSUser {

  /** BASF organization code */
  orgCode?: string;

  /** Email */
  mail?: string;

  /** First name */
  givenName?: string;

  /** BASF ID */
  id?: string;

  /** First name + surname */
  fullName?: string;

  /** Surname */
  surname?: string;

  /** List of LDAP groups */
  groups?: string[];

}

export function mapUser(userData: any, config: UserMapping): FSUser {
  return {
    fullName: userData[config.fullName],
    givenName: userData[config.givenName],
    surname: userData[config.surname],
    mail: userData[config.mail],
    groups: userData[config.groups],
    id: userData[config.id],
    orgCode: userData[config.orgCode]
  };
}
