<app-card-layout [title]="'Contract Negotiations'">
  <app-table [dataSource]="dataSource" [dataLoaded]="dataLoaded">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by id">ID</th>
        <td mat-cell *matCellDef="let element">
          {{ element.id }}
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by type">Type</th>
        <td mat-cell *matCellDef="let element">{{ element.type }}</td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by state">State</th>
        <td mat-cell *matCellDef="let element">{{ element.state }}</td>
      </ng-container>

      <ng-container matColumnDef="counterPartyId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by counterPartyId">
          Counterparty
          BPN
        </th>
        <td mat-cell *matCellDef="let element">{{ element.counterPartyId }}</td>
      </ng-container>

      <ng-container matColumnDef="counterPartyAddress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by counterPartyAddress">
          Counterparty Address
        </th>
        <td mat-cell *matCellDef="let element">{{ element.counterPartyAddress }}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by createdAt">Date</th>
        <td mat-cell *matCellDef="let element">{{ element.createdAt }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </app-table>
</app-card-layout>
