import {Component, Input} from '@angular/core';

/** Wrapping component for Login, Logout and Not Authorized pages, providing the common page skeleton */
@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent {

  /** Text which will to be shown above the centered rectangle */
  @Input() text: string;

  /** @ignore */
  constructor() { }

}
