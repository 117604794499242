export interface DigitalTwin {
  id: string
  cxIdentification: string
  cxGlobalAssetId: string
  production: boolean
  isRegistered: boolean
  bpn: string
  materialNumberBASF: string
  batchNumber: string,
  digitalTwinType: DigitalTwinType,
  customerList: Customer[]
}

export enum DigitalTwinType {
  PartInstance = 'PartInstance',
  PartType = 'PartType'
}

export interface Customer {
  customerName: string
  customerMaterialNumber: string
  customerBpn: string
  bilateralContract: string
}
