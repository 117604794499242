import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {AuthenticationService} from '../authentication/authentication.service';

/**
 * Intercepts all (except the ones going directly to the federation service and the ones mentioned in TitaniumConfig.excludeURLs[])
 * requests and adds a bearer access token into the headers for proper functioning with oAuth.
 */
@Injectable()
export class AuthenticationInterceptorService implements HttpInterceptor {

  /** @ignore */
  private isRefreshingToken = false;
  /** @ignore */
  private authService: AuthenticationService;
  /** @ignore */
  private router: Router;

  /** @ignore */
  constructor(
    private injector: Injector,
  ) {
    this.authService = this.injector.get(AuthenticationService);
    this.router = this.injector.get(Router);
  }

  /** The main method doing all the work.
   * It won't enhance the request with the access token when:
   * it is going directly to the federation service and if it is mentioned in TitaniumConfig.excludeURLs[].
   * If the token is going to be expired in 1 hour it will refresh the token.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.authService.isFederationServiceTurnedOn()) {
      return this.handleRequest(req, next);
    }
    if (this.couldBeRequestURLUsedWithoutAuthentication(req.url)) {
      return next.handle(req);
    }
    let isTokenExpired: boolean;
    try {
      isTokenExpired = this.authService.isTokenExpired();
    } catch (error) {
      this.logoutUser();
      return EMPTY;
    }
    if (isTokenExpired) {
      return this.refreshToken(req, next);
    }
    return this.handleRequest(this.addToken(req, this.authService.getAuthToken()), next);
  }

  /** @ignore */
  private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      setHeaders: { Authorization: 'Bearer ' + token },
      withCredentials: false
    });
  }

  /** @ignore */
  private refreshToken(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      return this.authService.refreshToken()
        .pipe(
          switchMap((newToken: any) => {
            return this.handleRequest(this.addToken(req, newToken.access_token), next);
          }
          )
        );
    } else {
      return this.handleRequest(this.addToken(req, this.authService.getAuthToken()), next);
    }
  }

  /** @ignore */
  private logoutUser() {
    this.authService.logout()
      .subscribe(() => {
        this.router.navigate(['logout'])
      });
  }

  /** @ignore */
  private handleRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401 && error.url.includes(environment.backendUrl)) {
              this.router.navigate(['not-authorized']);
            }
            console.log(error);
          }
          return throwError(error);
        })
      );
  }

  /** @ignore */
  private couldBeRequestURLUsedWithoutAuthentication(url: string): boolean {
    let excludedURLs = [
      '.json',
      '/news',
      environment.federationServiceUrl,
      environment.backendUrl.replace('v1/', '') + 'token'
    ];
    return excludedURLs.some(urlToExclude => url.includes(urlToExclude));
  }

}
