import { Component, Inject } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Observable, startWith } from 'rxjs';
import { map } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { HttpHeaders } from '@angular/common/http';

import { SharedItem } from 'src/app/shared/models/shareditem/shared-item';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { SharedItemDTO } from 'src/app/shared/models/shareditem/shared-item.dto';
import { SharedProductDTO } from 'src/app/shared/models/shareditem/shared-product.dto';

@Component({
  selector: 'app-shared-product-new',
  templateUrl: './shared-product-new.component.html',
  styleUrls: ['./shared-product-new.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatIconModule,
    MatSnackBarModule
  ]
})
export class SharedProductNewComponent {
  form: FormGroup;
  sharedItem: SharedItem;
  showChangeConfigurationMessage: boolean = false;

  productList: any = [];
  filteredProductList: any = [];
  productNameControl = new FormControl<string>('');
  filteredProductListObservable: Observable<any[]>;
  selectedProductList: any = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('productInput') productInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private dialogRef: MatDialogRef<SharedProductNewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      sharedItem: SharedItem,
      showChangeConfigurationMessage: boolean
    }
  ) { 
    this.sharedItem = data.sharedItem;
    this.showChangeConfigurationMessage = data.showChangeConfigurationMessage;
  }

  ngOnInit() {
    this.form = this.fb.group({
      customer: [
        {
          value: (this.sharedItem.customerName) ? this.sharedItem.customerName : this.sharedItem.customerNumber,
          disabled: true
        },
        Validators.required
      ]
    });

    this.filteredProductListObservable = this.productNameControl.valueChanges.pipe(
      startWith(null),
      map((product: string | null) => {
        return product ? this._filterProducts(product) : this.filteredProductList.slice();
      }),
    );

    this.getMaterialList();
  }

  getMaterialList(): void {
    if (this.sharedItem) {
      this.http.get<any>('/api/v1/delivery-filtered/material-list/' + this.sharedItem.customerNumber + "/" + this.sharedItem.odName, this.HttpOptions).subscribe(
        (response: any) => {
          this.productList = response;
          this.filteredProductList = this.productList;
          this.productNameControl.setValue(null);
        });
    }
  }

  private _filterProducts(value: any): any[] {
    let filterValue: string;
    if (typeof value === 'string') {
      filterValue = value.toLowerCase();
    } else if (value?._id && typeof value._id.materialName === 'string') {
      filterValue = value._id.materialName.toLowerCase();
    } else {
      return [];
    }
    return this.filteredProductList.filter(product => product._id.materialName.toLowerCase().includes(filterValue));
  }

  addProduct(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    if (value) {
      this.selectedProductList.push(value);
      this.filteredProductList = this.filteredProductList.filter(m => m._id.materialName !== value._id.materialName);
    }
    this.productInput.nativeElement.value = '';
    this.productNameControl.setValue(null);
  }

  addManualProduct(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const value = event.value.trim();
      const onlyNumbersRegex = /^\d{1,18}$/;

      if (value) {
        if (onlyNumbersRegex.test(value)) {
          const paddedValue = value.padStart(18, '0');
          const isDuplicate = this.selectedProductList.some(item => item._id.materialNumber === paddedValue);
          if (!isDuplicate) {
            const material = {
              _id: {
                materialName: '',
                materialNumber: paddedValue
              }
            };
            this.selectedProductList.push(material);
            this.filteredProductList = this.filteredProductList.filter(m => m._id.materialNumber !== paddedValue);
          }

          this.productInput.nativeElement.value = '';
          this.productNameControl.setValue(null);
        } else {
          this.activateAlert('red-snackbar', 'Product number must contain only digits and have a maximum length of 18.');
        }
      }
    }
  }

  removeProduct(material: any): void {
    const index = this.selectedProductList.indexOf(material);
    if (index >= 0) {
      const existsMaterialInMaterialList = this.productList.some(item => item._id.materialNumber === material._id.materialNumber);

      if (existsMaterialInMaterialList) {
        this.filteredProductList.push(material);
      }

      this.selectedProductList.splice(index, 1);
      this.productNameControl.setValue(null);
    }
  }

  displayFnProduct(product: any): string {
    return product && product._id.materialName ? product._id.materialName : '';
  }

  onSubmit() {
    if (this.selectedProductList.length === 0) {
      this.activateAlert('red-snackbar', 'The product list is empty. Please add at least one product before submitting.');
      return;
    }

    let productList: SharedProductDTO[] = this.selectedProductList.map(product => ({
      productNumber: product._id.materialNumber,
      productName: product._id.materialName
    }));

    const formData: SharedItemDTO = {
      odName: this.sharedItem.odName,
      customerName: this.sharedItem.customerName,
      customerNumber: this.sharedItem.customerNumber,
      productList: productList,
      allProductsSelected: false,
    };

    this.http.post<SharedItemDTO>("/api/v1/shared-item/shared-product", formData, this.HttpOptions).subscribe({
      next: () => {
        this.dialogRef.close(this.form.value);
        this.activateAlert('green-snackbar', 'Shared Products have been added successfully!');
      },
      error: (error) => {
        if (error.status == 500 || error.status == 405) {
          this.activateAlert('red-snackbar', error.error.error);
        } else {
          this.activateAlert('red-snackbar', error.error);
        }
      }
    });


    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  activateAlert(type: string, message: string) {
    this.snackBar.open(message, '', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: [type]
    });
  }

  get HttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  }
}