<div class="container">
  <h1 class="title">{{text}}</h1>
  <div class="content">
    <mat-card class="card">
      <mat-card-content>
        <ng-content></ng-content>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="logo-container">
    <div>
      <img src="../../../assets/images/basf-logo.svg" class="basf-logo"
        alt="BASF company logo">
    </div>
  </div>
</div>