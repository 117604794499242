import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {AuthenticationService} from "../../services/authentication/authentication.service";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrl: './logout.component.css'
})
export class LogoutComponent {

  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.logout()
      .subscribe(() => { });
  }

  goToLanding() {
    this.router.navigate(['landing']);
  }
}
