<main class="content">
  <div class="main-column">
    <section class="welcome">
      <h1>Welcome to Material Passport</h1>
      <p><b>Dear user,</b> Welcome to Material Passport, the BASF Internal Portal for Catena-X Operations. </p>
      <p>Through this portal, you will be able to access the complete public catalog of BASF Digital Twins for the
        Catena-X Ecosystem. Additionally, you will gain transparency into which specific data can be requested and by
        which customers in the network. </p>
      <p>Here you can find relevant theoretical frameworks of key concepts to keep in mind when operating in Catena-X.
        For any additional queries, please do not hesitate to contact us for further support at 📧 <a
          href="mailTo:customerFacing-dataServices&#64;basf.com"> customerFacing-dataServices&#64;basf.com</a></p>


      <div class="main-image">
        <img src="../../assets/images/generated_car.png" alt="Futuristic concept car">
        <div class="image-caption">Image generated by AI</div>
      </div>

      <div class="value-chain">
        <h2>The Automotive Industry Value Chain</h2>
        <div class="image-container">
          <img src="../../assets/images/automotive_industry_supply_chanin.png"
            alt="Automotive Industry Data Chain Flow">
        </div>
      </div>
    </section>

    <section class="od-categories">
      <h2>Automotive Industry Committee</h2>
      <div class="od-categories-container">
        <div class="box">Performance Materials</div>
        <div class="box">Monomers</div>
        <div class="box">Coatings</div>
        <div class="box">Catalysts</div>
        <div class="box">Performance Chemicals</div>
      </div>
    </section>
  </div>

  <div class="side-column">
    <aside class="catena-info" aria-label="Catena-X Info">
      <div class="logo-catena-1">
        <img src="../../assets/images/catena_x_logo2.jpg" alt="Catena-X Logo">
      </div>
      <div class="logo-catena-2">
        <img src="../../assets/images/cofinity_x_logo3.jpg" alt="Cofinity-X Logo">
      </div>
      <div class="link-column">
        <h3>Other Links</h3>
        <ul>
          <li><a href="https://performance-materials.basf.net/portal/basf/en/dt.jsp?setCursor=1_3005611"
              target="_blank">Global Automotive Steering Committee</a></li>
          <li><a href="https://catena-x.net/" target="_blank">Catena-X Site</a></li>
          <li><a href="https://www.cofinity-x.com/" target="_blank">Cofinity-X GmbH</a></li>
        </ul>
      </div>
    </aside>
    <aside class="faq" aria-label="Frequently Asked Question">
        <h3>FAQ</h3>
        <nav aria-label="Frequently Asked Questions">
          <ul>
            <li><a href="/faq#catena-x">Catena-X</a></li>
            <li><a href="/faq#digital-twin">What is a Digital Twin?</a></li>
            <li><a href="/faq#aspect-model">What is an Aspect Model?</a></li>
            <li><a href="/faq#digital-twin-registry">What is the Digital Twin Registry of BASF?</a></li>
            <li><a href="/faq#digital-product-passport">What is a Digital Product Passport?</a></li>
            <li><a href="/faq#edc">What is the EDC?</a></li>
          </ul>
        </nav>
    </aside>
  </div>
</main>