import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-confirm-delete-popup',
  templateUrl: './confirm-delete-popup.component.html',
  styleUrls: ['./confirm-delete-popup.component.css'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
})
export class ConfirmDeletePopupComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDeletePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.dialogRef.updatePosition({ top: '200px' });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface DialogData {
  elementToDelete: string;
  id: string;
}
