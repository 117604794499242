import { Component, Input } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent {
  @Input() isUserLogged: boolean;

  env = environment;
  loggedFullName: string;

  constructor(private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.loggedFullName = this.authenticationService.user?.fullName || this.authenticationService.user?.givenName + ' ' + this.authenticationService.user?.surname;
  }
}
