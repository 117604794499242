import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssetDataService {
  private assetIdSource = new BehaviorSubject<string | null>(null);
  currentAssetId = this.assetIdSource.asObservable();

  constructor() {
  }

  changeAssetId(assetId: string) {
    this.assetIdSource.next(assetId);
  }
}
