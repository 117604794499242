<app-card-layout>
  <section class="contact-info">
    <h2 class="contact-title">App Contact</h2>
    <p>Guel Bekcioglu-Neff</p>
    <p>
      <a href="mailto:guel.bekcioglu-neff@basf.com" style="margin-top: 1em;">
        <mat-icon>email</mat-icon>
        Send Email
      </a>
    </p>
  </section>

  <section class="hosting-info">
    <h2>Hosting of the Server</h2>
    <address>
      BASF Digital Solutions GmbH<br>
      Information Processing and Network Services<br>
      Pfalzgrafenstr. 1, D-67061 Ludwigshafen
    </address>
    <p>
      <a href="mailto:customerFacing-dataServices@basf.com">
        <mat-icon>email</mat-icon>
        Send Email
      </a>
    </p>
  </section>
</app-card-layout>