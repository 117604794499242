// @dynamic
/** Accesses the LocalStorage in order to retrieve the stored information needed for authentication. */
export class TokenInformation {

  /** @ignore */
  static ACCESS_TOKEN = 'access_token';
  /** @ignore */
  static TOKEN_TYPE = 'token_type';
  /** @ignore */
  static EXPIRES_IN = 'expires_in';
  /** @ignore */
  static REFRESH_TOKEN = 'refresh_token';
  /** @ignore */
  static ID_TOKEN = 'id_token';

  /** Initializes the TokenInformation object with the data stored in LocalStorage */
  static initalize(): TokenInformation {
    return new TokenInformation(
      this.getFromLocalStorage(TokenInformation.ACCESS_TOKEN),
      this.getFromLocalStorage(TokenInformation.TOKEN_TYPE),
      this.getFromLocalStorage(TokenInformation.EXPIRES_IN),
      this.getFromLocalStorage(TokenInformation.REFRESH_TOKEN),
      this.getFromLocalStorage(TokenInformation.ID_TOKEN)
    );
  }

  /**
   * Retrieves a value from LocalStorage.
   * @param key the key for retrieving the value
   */
  static getFromLocalStorage(key: string): any {
    const item: any = localStorage.getItem(key);
    if (item === 'null') {
      return null;
    } else if (item === 'undefined') {
      return undefined;
    }
    return item;
  }

  /**
   * Writes a value to LocalStorage.
   * @param key the key for identifying the value
   * @param value value you want to store
   */
  static setToLocalStorage(key: string, value: string): any {
    localStorage.setItem(key, value);
  }

  /** Parameters passed here will be automatically writen into the LocalStorage. */
  constructor(accessToken: string, tokenType: string, expiresIn: number, refreshToken: string, idToken: string) {
    this.accessToken = accessToken;
    this.tokenType = tokenType;
    this.expiresIn = expiresIn;
    this.refreshToken = refreshToken;
    this.idToken = idToken;
  }

  /** @ignore */
  public get accessToken(): string {
    return TokenInformation.getFromLocalStorage(TokenInformation.ACCESS_TOKEN);
  }

  /** @ignore */
  public set accessToken(value: string) {
    TokenInformation.setToLocalStorage(TokenInformation.ACCESS_TOKEN, value);
  }

  /** @ignore */
  public get tokenType(): string {
    return TokenInformation.getFromLocalStorage(TokenInformation.TOKEN_TYPE);
  }

  /** @ignore */
  public set tokenType(value: string) {
    TokenInformation.setToLocalStorage(TokenInformation.TOKEN_TYPE, value);
  }

  /** @ignore */
  public get expiresIn(): number {
    const expiresInNumber = Number.parseInt(TokenInformation.getFromLocalStorage(TokenInformation.EXPIRES_IN));
    return isNaN(expiresInNumber) ? null : expiresInNumber;
  }

  /** @ignore */
  public set expiresIn(value: number) {
    TokenInformation.setToLocalStorage(TokenInformation.EXPIRES_IN, value ? value.toString() : null);
  }

  /** @ignore */
  public get refreshToken(): string {
    return TokenInformation.getFromLocalStorage(TokenInformation.REFRESH_TOKEN);
  }

  /** @ignore */
  public set refreshToken(value: string) {
    TokenInformation.setToLocalStorage(TokenInformation.REFRESH_TOKEN, value);
  }

  /** @ignore */
  public get idToken(): string {
    return TokenInformation.getFromLocalStorage(TokenInformation.ID_TOKEN);
  }

  /** @ignore */
  public set idToken(value: string) {
    TokenInformation.setToLocalStorage(TokenInformation.ID_TOKEN, value);
  }

  /** Removes all the token related information from the LocalStorage. */
  public clearLocalStorage(): void {
    localStorage.removeItem(TokenInformation.ACCESS_TOKEN);
    localStorage.removeItem(TokenInformation.TOKEN_TYPE);
    localStorage.removeItem(TokenInformation.EXPIRES_IN);
    localStorage.removeItem(TokenInformation.REFRESH_TOKEN);
    localStorage.removeItem(TokenInformation.ID_TOKEN);
  }
}
