import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.css'
})
export class FaqComponent {
  faqSections = [
    {
      id: "catena-x",
      title: "What is Catena-X?",
      content: "The first collaborative ecosystem for the Automotive Industry, aimed at creating a \
      consistent, secure, efficient, and resilient value chain by ensuring the quality and sovereignty of standardized data."
    },
    {
      id: "digital-twin",
      title: "What is a Digital Twin?",
      content: "A digital model that reflects a real object's life cycle. It uses real-time data for \
      analysis and control, mirroring the physical version's behavior and state."
    },
    {
      id: "aspect-model",
      title: "What is an Aspect Model?",
      content: "It is a data model that organizes and details distinct features of a digital twin or \
      asset. It supports modular information handling for simpler management and analysis."
    },
    {
      id: "digital-twin-registry",
      title: "What is the Digital Twin Registry?",
      content: "The Digital Twin Registry serves as a ledger that provides a unique identity to each \
      digital twin from a company within the network."
    },
    {
      id: "digital-product-passport",
      title: "What is a DPP?",
      content: "A Digital Product Passport is a digital record detailing a product's traits, origin, \
      and lifecycle, enhancing supply chain transparency and traceability."
    },
    {
      id: "edc",
      title: "What is the EDC?",
      content: "The Eclipse Dataspace Connector (EDC) ensures secure data exchange. You can think of \
      it as a contractually secured interface, via which defined partners can exchange their data in a legible and legally compliant manner. "
    },
  ];

  expandedSection: string | null = null;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        this.expandedSection = fragment;
        setTimeout(() => {
          const element = document.getElementById(fragment);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 300);
      }
    });
  }
}
