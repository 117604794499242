import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from "@angular/material/snack-bar";
import {AssetDataService} from "./edc-asset-data.service";

interface Method {
  value: string;
  enabled: boolean;
}

@Component({
  selector: 'app-edc-asset-data',
  templateUrl: './edc-asset-data.component.html',
  styleUrls: ['./edc-asset-data.component.css']
})
export class EdcAssetDataComponent implements OnInit {
  form: FormGroup;
  showBodyField: boolean = false;
  assetId: string;
  breadcrumb = [{title: 'Data Asset', href: ''}];
  resultLoaded: boolean = false;

  methods: Method[] = [
    {value: 'GET', enabled: true},
    {value: 'POST', enabled: true},
    {value: 'PUT', enabled: false},
    {value: 'DELETE', enabled: false},
  ]

  assetDataResult: string = '{ Empty }'

  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private assetDataService: AssetDataService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.assetDataService.currentAssetId.subscribe(assetId => {
      this.resultLoaded = true;
      this.form = this.formBuilder.group({
        method: ['GET', [Validators.required]],
        assetId: [assetId, [Validators.required]],
        endpoint: ['', []],
        body: ['']
      });
      this.form.get('method').valueChanges.subscribe(value => {
        this.showBodyField = (value === 'POST');
        const bodyControl = this.form.get('body');

        if (this.showBodyField) {
          bodyControl.setValidators([Validators.required]);
        } else {
          bodyControl.setValidators(null);
          bodyControl.reset();
        }

        bodyControl.updateValueAndValidity();
        this.cdr.detectChanges();
      });
    });
  }

  getAssetData() {
    this.resultLoaded = false;
    if (this.form.valid) {
      const assetConsumptionRequestDTO = {
        method: this.form.value.method,
        assetId: this.form.value.assetId,
        endpoint: this.form.value.endpoint,
        body: this.form.value.body || null
      };

      if (assetConsumptionRequestDTO.method === 'POST' && assetConsumptionRequestDTO.body) {
        try {
          if (typeof assetConsumptionRequestDTO.body === 'string') {
            assetConsumptionRequestDTO.body = JSON.parse(assetConsumptionRequestDTO.body);
          }
        } catch (e) {
          this.snackBar.open('Invalid JSON format', '', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
          return;
        }
      } else {
        delete assetConsumptionRequestDTO.body;
      }

      this.http.post<any>(`/api/v1/edc/data`, assetConsumptionRequestDTO, this.httpOptions).subscribe(
        (response) => {
          this.assetDataResult = JSON.stringify(response, null, 2);
          this.resultLoaded = true;
        },
        (error) => {
          this.snackBar.open(`Error: ${error.message}`, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
          this.resultLoaded = true;
        }
      );
    }
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.assetDataResult).then(
      () => this.snackBar.open('Copied to clipboard', '', {
        duration: 2000,
        verticalPosition: 'top',
      }),
      (err) => console.error('Could not copy text: ', err)
    );
  }

  get httpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  }

  get enabledMethods(): Method[] {
    return this.methods.filter(method => method.enabled);
  }

}
