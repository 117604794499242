<app-card-layout [title]="digitalTwinPageTitle" [breadcrumb]="breadcrumb">
  <form [formGroup]='form'>

    <mat-form-field appearance="outline" class="width-48 m-r-4">
      <mat-label>CX Identification</mat-label>
      <input formControlName="cxIdentification" matInput readonly>
    </mat-form-field>

    <mat-form-field appearance="outline" class="width-48">
      <mat-label>CX Global Asset Id</mat-label>
      <input formControlName="cxGlobalAssetId" matInput readonly>
    </mat-form-field>

    <mat-form-field appearance="outline" class="width-48 m-r-4">
      <mat-label>Business Partner Number</mat-label>
      <input formControlName="bpn" matInput readonly>
    </mat-form-field>

    <mat-form-field appearance="outline" class="width-48">
      <mat-label>BASF Material Number</mat-label>
      <input formControlName="materialNumberBASF" matInput readonly>
    </mat-form-field>

    <mat-form-field appearance="outline" class="width-48 m-r-4" floatLabel="always">
      <mat-label>Digital Twin Type</mat-label>
      <input formControlName="digitalTwinType" matInput readonly>
    </mat-form-field>

    <ng-container *ngIf="form.get('digitalTwinType').value !== 'Part Type'">
      <mat-form-field appearance="outline" class="width-48">
        <mat-label>Batch Number</mat-label>
        <input formControlName="batchNumber" matInput readonly>
      </mat-form-field>
    </ng-container>

    <div class="text-wrapper">
      <div class="text-wrapper-title">
        <div>
          Customers
        </div>
      </div>
      <div formArrayName="customerList">
        <div *ngFor="let customerFormGroup of customerList.controls; let i = index" [formGroupName]="i"
             style="margin-bottom: 5px;" class="align-items-center">
          <mat-form-field appearance="outline" class="width-20 m-r-2">
            <mat-label>Customer Material No.</mat-label>
            <input formControlName="customerMaterialNumber" matInput readonly>
          </mat-form-field>

          <mat-form-field appearance="outline" class="width-20 m-r-2">
            <mat-label>Customer BPN</mat-label>
            <input formControlName="customerBpn" matInput readonly>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="text-wrapper">
      <div class="text-wrapper-title">
        <div>
          Submodels
        </div>
      </div>
      <div formArrayName="submodelList">
        <div *ngFor="let submodelFormGroup of submodelList.controls; let i = index" [formGroupName]="i"
             style="margin-bottom: 5px;" class="align-items-center">
          <mat-form-field appearance="outline" class="width-20 m-r-2">
            <input formControlName="valuePart" matInput readonly>
          </mat-form-field>
        </div>
      </div>
    </div>

  </form>

</app-card-layout>
