<h1 mat-dialog-title>Add Products</h1>
<mat-dialog-content>
  <form [formGroup]="form" class="form">
    <div *ngIf="showChangeConfigurationMessage" id="change-configuration-message">
      Important! In order to change the configuration to 'Selected products' you must add at least one product. If 
      you close this form without adding any products the configuration will remain as 'All products'.
    </div>
    <mat-form-field appearance="outline">
      <mat-label>Customer</mat-label>
      <input formControlName="customer" matInput>
    </mat-form-field>

    <mat-form-field appearance="outline" class="width-48" floatLabel="always">
      <mat-label>Product List</mat-label>
      <mat-chip-grid #chipList aria-label="Product selection">
        <mat-chip-row *ngFor="let product of selectedProductList" (removed)="removeProduct(product)">
          {{ product._id.materialName ? product._id.materialName : product._id.materialNumber }}
          <button matChipRemove
            [attr.aria-label]="'remove ' + (product._id.materialName ? product._id.materialName : product._id.materialNumber)">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <input placeholder="Select product or add a new product number..." #productInput
        [formControl]="productNameControl" [matChipInputFor]="chipList" [matAutocomplete]="auto"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addManualProduct($event)" />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnProduct"
        (optionSelected)="addProduct($event)">
        @for (option of filteredProductListObservable | async; track option) {
        <mat-option [value]="option">{{ option._id.materialName }}</mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="mat-dialog-actions">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="primary" (click)="onSubmit()">Add Products</button>
</mat-dialog-actions>