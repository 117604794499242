<div *ngIf="panelText !== undefined && panelText !== null">
  <br>
  <mat-expansion-panel *ngIf="panelText !== undefined" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ panelTitle }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <pre id="panelText">{{ panelText }}</pre>
  </mat-expansion-panel>
</div>
