import {Component, Input} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent {
  @Input() breadcrumbList: Breadcrumb[] = []

  breadcrumbList$: Observable<Breadcrumb[]>;
  private _breadcrumbListSubject: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<Breadcrumb[]>([]);

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.breadcrumbList$ = this._breadcrumbListSubject.asObservable();
  }


  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const root: ActivatedRouteSnapshot = this.activatedRoute.snapshot;
      const breadcrumbs: Breadcrumb[] = this.getBreadcrumbs(root);
      this._breadcrumbListSubject.next(breadcrumbs);
    });
  }

  getBreadcrumbs(route: ActivatedRouteSnapshot, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    const ROUTE_DATA_BREADCRUMB: string = 'breadcrumb';

    //get the child routes
    let children: ActivatedRouteSnapshot[] = route.children;

    //return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    //iterate over each children
    for (let child of children) {
      //verify primary route
      if (child.outlet !== 'primary') {
        continue;
      }

      //verify the custom data property 'breadcrumb' is specified on the route
      if (!child.routeConfig || !child.routeConfig.data || !child.routeConfig.data[ROUTE_DATA_BREADCRUMB]) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      //get the route's URL segment
      let routeURL: string = child.url.map(segment => segment.path).join('/');

      //append route URL to URL
      url += `/${routeURL}`;

      //add breadcrumb
      let breadcrumb: Breadcrumb = {
        title: child.routeConfig.data[ROUTE_DATA_BREADCRUMB],
        href: url
      };
      breadcrumbs.push(breadcrumb);

      //recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }

    //we should never get here, but just in case
    return [];
  }
}

export interface Breadcrumb {
  title: string
  href: string
}
