import {Component, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {MatDialog} from '@angular/material/dialog';

import {ConfirmDeletePopupComponent} from '../../shared/confirm-delete-popup/confirm-delete-popup.component';
import {ConfirmPopupComponent} from "../../shared/confirm-popup/confirm-popup.component";
import {MessageService} from '../../shared/services/message.service';

import {MatSnackBar} from '@angular/material/snack-bar';
import {UniqueIdPushNotification} from "../../shared/models/notifications/uniqueidpush-notification";

@Component({
  selector: 'app-uniqueidpush-notification',
  templateUrl: './uniqueidpush-notification.component.html',
  styleUrls: ['./uniqueidpush-notification.component.css']
})
export class UniqueIdPushNotificationComponent {
  dataSource = new MatTableDataSource<UniqueIdPushNotification>();
  dataLoaded = false;
  displayedColumns: string[] = ['messageId', 'digitalTwinType', 'senderBpn', 'sentDateTime', 'expectedResponseBy', 'isConnected', 'options'];
  dialogOpen = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    public dialog: MatDialog,
    public messageService: MessageService) {
    this.getUniqueIdPushNotifications();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getUniqueIdPushNotifications() {
    this.http.get<any>("/api/v1/notifications/uniqueidpush").pipe(map(res => res.map((x: UniqueIdPushNotification) => ({
      messageId: x.header.messageId,
      digitalTwinType: x.content.digitalTwinType,
      senderBpn: x.header.senderBpn,
      sentDateTime: x.header.sentDateTime,
      expectedResponseBy: x.header.expectedResponseBy,
      isConnected: x.isConnected
    })))).subscribe(
      res => {
        this.dataSource.data = res;
        this.dataLoaded = true;
      }
    );

    this.messageService.getMessage().subscribe(message => {
      if (message) {
        this.snackBar.open(message.text, '', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['green-snackbar']
        });
      }
    });
  }

  openUniqueIdPushNotificationPopup(notificationId: string) {
    this.dialogOpen = true;
    this.http.get(`/api/v1/notifications/uniqueidpush/${notificationId}`).subscribe(
      (response: any) => {
        let json: string = JSON.stringify(response, null, 2);

        this.dialog.open(ConfirmPopupComponent, {
          data: {
            dialogTitle: 'View Unique ID Push Notification',
            dialogContent: `<pre id="pre">${json}</pre>`,
            buttonText: 'Ok'
          },
          position: {top: '100px'},
          maxWidth: '90vw',
          width: '60vw'
        }).afterClosed().subscribe(result => {
          this.dialogOpen = false;
        });
      },
      error => {
        console.error('Error fetching Unique ID push notification:', error);
      }
    );
  }

  openDeleteConfirmationPopup(messageId: string) {
    const dialogRef = this.dialog.open(ConfirmDeletePopupComponent, {
      data: {elementToDelete: 'Unique ID Push Notification', id: messageId},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined)
        this.deleteUniqueIdPushNotification(messageId);
    });
  }

  connectToChild(messageId: string) {
    this.http.post<{ message: string }>(`/api/v1/notifications/uniqueidpush/connect-to-child/${messageId}`, {})
      .subscribe(
        res => {
          this.snackBar.open(res.message || 'Successfully connected to child item!', '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['green-snackbar']
          });
        },
        error => {
          const errorMessage = error?.error?.error || 'An unexpected error occurred';
          this.snackBar.open(`Error: ${typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage)}`, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
        }
      );
  }



  deleteUniqueIdPushNotification(messageId: string) {
    this.http.delete(`/api/v1/notifications/uniqueidpush/${messageId}`).subscribe(
      () => {
        this.snackBar.open("Unique ID Push notification deleted successfully!", '', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['green-snackbar']
        });

        this.getUniqueIdPushNotifications()
      },
      (error) => {
        if (error.status == 500 || error.status == 405) {
          this.snackBar.open(error.error.error, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
        } else {
          this.snackBar.open(error.error, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
        }
      }
    )
  }
}
