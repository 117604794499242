
const ADMIN_ROUTES = [
  'productionNavItem', // Nav section in the sidebar menu
  'shared-items-new',
  'testNavItem', // Nav section in the sidebar menu
  'apiNavItem', // Nav section in the sidebar menu
  'notificationsNavItem', // Nav section in the sidebar menu
  'edcNavItem', // Nav section in the sidebar menu
];

const OD_ROUTES = [
  'shared-items-new'
];


export const GROUPS_PERMITTED_ROUTES = {
  CFDS_MATPASS_ADMIN: ADMIN_ROUTES,
  MATERIAL_PASS_DEVELOPER: ADMIN_ROUTES,
  MATERIAL_PASS_OD1: OD_ROUTES,
};
