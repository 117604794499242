<app-card-layout [title]="textButton" [breadcrumb]="breadcrumb">

  <form [formGroup]='form' (ngSubmit)="createOrModifyBatch()">
    <mat-form-field appearance="outline" class="width-48 m-r-4">
      <mat-label>Digital Twin Id Associated</mat-label>
      <input formControlName="digitalTwinId" matInput>
    </mat-form-field>

    <mat-form-field appearance="outline" class="width-48" *ngIf="isRegistered">
      <mat-label>CX Identification</mat-label>
      <input [value]="cxIdentificationListToString" matInput disabled>
    </mat-form-field>

    <mat-form-field appearance="outline" class="width-48">
      <mat-label>Material Classification</mat-label>
      <input formControlName="materialClassification" matInput>
    </mat-form-field>

    <mat-form-field appearance="outline" class="width-48 m-l-4">
      <mat-label>Material Name Manufacturer</mat-label>
      <input formControlName="materialNameManufacturer" matInput>
    </mat-form-field>

    <br>
    <button mat-raised-button color="primary" type="submit">
      {{ textButton }}
    </button>

  </form>
</app-card-layout>

<app-expansion-panel [panelTitle]="'Access Policy'" [panelText]="accessPolicyJson"></app-expansion-panel>
<app-expansion-panel [panelTitle]="'Contract Policy'" [panelText]="contractPolicyJson"></app-expansion-panel>
