<div *ngIf="breadcrumbList && breadcrumbList.length > 0">
  <ul class="breadcrumb">
    <ng-container *ngFor="let breadcrumb of breadcrumbList; let i = index">
      <li *ngIf="i < breadcrumbList.length - 1">
        <a [routerLink]="breadcrumb.href">{{ breadcrumb.title }}</a>
      </li>
      <li *ngIf="i === breadcrumbList.length - 1">
        {{ breadcrumb.title }}
      </li>
    </ng-container>
  </ul>
</div>
