<div class="tooltip-container" *ngIf="isVisible">
  <div class="tooltip-content">
    <ng-container *ngIf="tooltipType === 'digitalTwin'">
      <span class="label">BASF Material No.: </span><span class="value">{{ HelpersService.getTrimmedMaterialNumber(item.materialNumberBASF) }}</span><br>
      <span class="label">BASF BPN: </span><span class="value">{{ item.bpn }}</span><br>
      <span class="label">Customer BPN: </span><span class="value">{{ getFormattedCustomerBPNList(item.customerBPNList) }}</span><br>
      <span class="label">Type: </span><span class="value">{{ item.digitalTwinType }}</span>
    </ng-container>
  </div>
</div>

