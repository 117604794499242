<div class="card-content">
  <div class="card-header">
    <div>
      <div class="card-title">{{ title }}</div>
      <app-nav [breadcrumbList]="breadcrumb"></app-nav>
    </div>

    <div *ngIf="buttonText && buttonRoute">
      <button mat-raised-button color="primary" (click)="onButtonClick()">
        <mat-icon>add</mat-icon>
        {{ buttonText }}
      </button>
    </div>
  </div>
  <div>
    <ng-content></ng-content>
  </div>
</div>