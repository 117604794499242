<div class="faq-container">
    <h1>Frequently Asked Questions</h1>

    <mat-accordion>
        <mat-expansion-panel *ngFor="let section of faqSections" [expanded]="expandedSection === section.id"
            (opened)="expandedSection = section.id" [id]="section.id">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{section.title}}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-list>
                <mat-list-item>
                    <p matLine>{{section.content}}</p>
                </mat-list-item>
            </mat-list>
        </mat-expansion-panel>
    </mat-accordion>
</div>