import {Component} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {MatDialog} from '@angular/material/dialog';
import {Clipboard} from '@angular/cdk/clipboard';
import {TransferProcess} from "../shared/models/transfer-process";

@Component({
  selector: 'app-edc-transfers',
  templateUrl: './edc-transfers.component.html',
  styleUrls: ['./edc-transfers.component.css']
})
export class EdcTransfersComponent {
  dataSource = new MatTableDataSource<TransferProcess>();
  dataLoaded = false;
  displayedColumns: string[] = ['id', 'state', 'stateTimestamp', 'edcType', 'assetId', 'contractId'];
  breadcrumb = [{title: 'Transfer Processes', href: ''}];

  showFirstLastButtons = true;

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private clipboard: Clipboard) {
    this.getTransferProcesses();
  }

  getTransferProcesses() {
    this.http.get<any>("/api/v1/edc/transferprocesses", {headers: {'Content-Type': 'application/json'}})
      .pipe(map(res => res.map((x: any) => ({
        id: x["@id"],
        state: x["edc:state"],
        stateTimestamp: new Date(x["edc:stateTimestamp"]).toLocaleString("es-ES"),
        edcType: x["edc:type"],
        assetId: x["edc:assetId"],
        contractId: x["edc:contractId"]
      })))).subscribe(
      res => {
        this.dataSource.data = res;
        this.dataLoaded = true;
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.replace(/^0+/, '');

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
