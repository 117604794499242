import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MatDialog } from '@angular/material/dialog';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { MatSnackBar } from "@angular/material/snack-bar";
import { MatStepper } from "@angular/material/stepper";
import { MessageService } from '../shared/services/message.service';
import { Observable, startWith } from "rxjs";
import { map } from "rxjs/operators";
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

import { SharedItemDTO } from '../shared/models/shareditem/shared-item.dto';
import { SharedProductDTO } from '../shared/models/shareditem/shared-product.dto';
import { AuthenticationService } from '../core/services/authentication/authentication.service';
import { Group } from '../core/models/group';

interface modelA {
  _id: modelB;
}

interface modelB {
  partnerName: string;
  partnerID: string;
  od: string;
}

interface OdObject {
  name: string;
  disabled: boolean
}

@Component({
  selector: 'app-shared-item-new',
  templateUrl: './shared-item-new.component.html',
  styleUrls: ['./shared-item-new.component.css']
})
export class SharedItemNewComponent implements OnInit {
  formGroup: FormGroup;
  breadcrumb = [
    { title: 'Shared Items', href: `/shared-item` },
    { title: 'Create Shared Items', href: '' }
  ];
  odList: OdObject[];

  partnerList: any = [];
  filteredPartnerList: any = [];
  partnerNameControl = new FormControl<string>('');
  filteredPartnerListObservable: Observable<any[]>;
  selectedPartnerList: any = [];
  partnerPlaceholder = 'Select customer or add a new customer number...';

  materialList: any = [];
  filteredMaterialList: any = [];
  materialNameControl = new FormControl<string>('');
  filteredMaterialListObservable: Observable<any[]>;
  selectedMaterialList: any = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('materialInput') materialInput: ElementRef<HTMLInputElement>;
  @ViewChild('partnerInput') partnerInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  constructor(
    private _formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private router: Router,
    public dialog: MatDialog,
    public messageService: MessageService,
    public authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.formGroup = this._formBuilder.group({
      formArray: this._formBuilder.array([
        this._formBuilder.group({
          odName: ['', Validators.required]
        }),
        this._formBuilder.group({
          partnerName: ['', Validators.required],
        }),
        this._formBuilder.group({
          selectAllProducts: [true, Validators.required]
        }),
      ])
    });

    this.getPartnerList();
    this.setInputObservables();
  }

  getPartnerList(): void {
    this.http.get<any>('/api/v1/delivery-filtered/partner-list', this.HttpOptions).subscribe(
      (response: any) => {
        this.partnerList = response;
        this.setOdList();
      })
  }

  setOdList(): void {
    const adminUser = this.authenticationService.isUserInAtLeastInOneOfGroups([Group.ADMIN, Group.DEVELOPER]);
    const userOdOwner = this.authenticationService.getOdFromOrgCode();
    const odMap = new Map();

    this.partnerList.forEach(item => {
        const odName = item._id.od;
        if (!odMap.has(odName)) {
            let disabled: boolean = true;

            if(adminUser || odName === userOdOwner
              || (odName === 'PM' && userOdOwner === 'EG')
            )
              disabled = false;

            odMap.set(odName, { name: odName, disabled });
        }
    });

    this.odList = Array.from(odMap.values());
}

  setInputObservables(): void {
    this.filteredPartnerListObservable = this.partnerNameControl.valueChanges.pipe(
      startWith(null),
      map((partner: string | null) => {
        return partner ? this._filterPartners(partner) : this.filteredPartnerList.slice();
      }),
    );

    this.filteredMaterialListObservable = this.materialNameControl.valueChanges.pipe(
      startWith(null),
      map((material: string | null) => {
        return material ? this._filterMaterials(material) : this.filteredMaterialList.slice();
      }),
    );
  }

  addMaterial(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    if (value) {
      this.selectedMaterialList.push(value);
      this.filteredMaterialList = this.filteredMaterialList.filter(m => m._id.materialName !== value._id.materialName);
    }
    this.materialInput.nativeElement.value = '';
    this.materialNameControl.setValue(null);
  }

  addManualMaterial(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const value = event.value.trim();
      const onlyNumbersRegex = /^\d{1,18}$/;

      if (value) {
        if (onlyNumbersRegex.test(value)) {
          const paddedValue = value.padStart(18, '0');
          const isDuplicate = this.selectedMaterialList.some(item => item._id.materialNumber === paddedValue);
          if (!isDuplicate) {
            const material = {
              _id: {
                materialName: '',
                materialNumber: paddedValue
              }
            };
            this.selectedMaterialList.push(material);
            this.filteredMaterialList = this.filteredMaterialList.filter(m => m._id.materialNumber !== paddedValue);
          }

          this.materialInput.nativeElement.value = '';
          this.materialNameControl.setValue(null);
        } else {
          this.activateAlert('Product number must contain only digits and have a maximum length of 18.');
        }
      }
    }
  }

  removeMaterial(material: any): void {
    const index = this.selectedMaterialList.indexOf(material);
    if (index >= 0) {
      const existsMaterialInMaterialList = this.materialList.some(item => item._id.materialNumber === material._id.materialNumber);

      if (existsMaterialInMaterialList) {
        this.filteredMaterialList.push(material);
      }

      this.selectedMaterialList.splice(index, 1);
      this.materialNameControl.setValue(null);
    }
  }

  addPartner(event: MatAutocompleteSelectedEvent): void {
    if (this.selectedPartnerList.length == 0) {
      const value = event.option.value;
      if (value) {
        this.selectedPartnerList.push(value);
        this.filteredPartnerList = this.filteredPartnerList.filter(m => m._id.partnerName !== value._id.partnerName);
      }
      this.partnerInput.nativeElement.value = '';
      this.partnerNameControl.setValue(null);
      this.partnerPlaceholder = '';
      this.selectedMaterialList = [];

    } else {
      this.activateAlert('Only one customer can be selected.');
    }
  }

  addManualPartner(event: MatChipInputEvent): void {
    if (this.selectedPartnerList.length == 0) {
      if (!this.matAutocomplete.isOpen) {
        const value = event.value.trim();
        const onlyNumbersRegex = /^\d{1,10}$/;

        if (value) {
          if (onlyNumbersRegex.test(value)) {
            const paddedValue = value.padStart(10, '0');
            const isDuplicate = this.selectedPartnerList.some(item => item._id.partnerNumber === paddedValue);
            if (!isDuplicate) {
              const partner = {
                _id: {
                  partnerName: '',
                  partnerID: paddedValue
                }
              };

              this.selectedPartnerList.push(partner);
              this.filteredPartnerList = this.filteredPartnerList.filter(m => m._id.partnerID !== paddedValue);
              this.partnerPlaceholder = '';
              this.selectedMaterialList = [];
            }

            this.partnerInput.nativeElement.value = '';
            this.partnerNameControl.setValue(null);
          } else {
            this.activateAlert('Customer number must contain only digits and have a maximum length of 10.');
          }
        }
      }
    } else {
      this.activateAlert('Only one customer can be selected.');
    }
  }

  removePartner(partner: modelA): void {
    const index = this.selectedPartnerList.indexOf(partner);

    if (index >= 0) {
      const existsPartnerInPartnerList = this.partnerList.some(item => item._id.partnerID === partner._id.partnerID);

      if (existsPartnerInPartnerList) {
        this.filteredPartnerList.push(partner);
      }

      this.selectedPartnerList.splice(index, 1);
      this.partnerNameControl.setValue(null);
      this.partnerPlaceholder = 'Select customer or add a new customer number...';
    }
  }

  submit() {
    if (!this.selectAllProducts && this.selectedMaterialList.length === 0) {
      this.activateAlert('The product list is empty. Please add at least one product before submitting.');
      return;
    }
    this.createSharedItems();
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    const currentStep = stepper.selectedIndex;

    if (currentStep === 1) {
      if (this.selectedPartnerList.length === 0) {
        this.activateAlert('A customer must be selected before going to the next step.');
        return;
      }

      this.getMaterialList();
    }

    stepper.next();
  }

  onSelectOD(odName: string) {
    this.filteredPartnerList = [];
    this.selectedPartnerList = [];
    this.partnerList.filter((item: any) => {
      if (item._id.od === odName) {
        this.filteredPartnerList.push(item);
      }
    });

    this.partnerNameControl.setValue(null);
    this.partnerPlaceholder = 'Select customer or add a new customer number...';
  }

  getMaterialList() {
    const partnerSelected: modelA = this.selectedPartnerList[0];

    if (partnerSelected) {
      this.http.get<any>('/api/v1/delivery-filtered/material-list/' + partnerSelected._id.partnerID + "/" + this.odName, this.HttpOptions).subscribe(
        (response: any) => {
          this.materialList = response;
          this.filteredMaterialList = this.materialList;
          this.materialNameControl.setValue(null);
        });
    }
  }

  displayFnMaterial(material: any): string {
    return material && material._id.materialName ? material._id.materialName : '';
  }

  displayFnPartner(partner: any): string {
    return partner && partner._id.partnerName ? partner._id.partnerName : '';
  }

  private _filterMaterials(value: any): any[] {
    let filterValue: string;
    if (typeof value === 'string') {
      filterValue = value.toLowerCase();
    } else if (value?._id && typeof value._id.materialName === 'string') {
      filterValue = value._id.materialName.toLowerCase();
    } else {
      return [];
    }
    return this.filteredMaterialList.filter(material => material._id.materialName.toLowerCase().includes(filterValue));
  }

  private _filterPartners(value: any): any[] {
    let filterValue: string;
    if (typeof value === 'string') {
      filterValue = value.toLowerCase();
    } else if (value?._id && typeof value._id.partnerName === 'string') {
      filterValue = value._id.partnerName.toLowerCase();
    } else {
      return [];
    }
    return this.filteredPartnerList.filter(partner => partner._id.partnerName.toLowerCase().includes(filterValue));
  }

  createSharedItems() {
    let productList: SharedProductDTO[] = [];

    if (!this.selectAllProducts) {
      productList = this.selectedMaterialList.map(material => ({
        productNumber: material._id.materialNumber,
        productName: material._id.materialName
      }));
    }

    const formData: SharedItemDTO = {
      odName: this.odName,
      customerName: this.selectedPartnerList[0]._id.partnerName,
      customerNumber: this.selectedPartnerList[0]._id.partnerID,
      productList: productList,
      allProductsSelected: this.selectAllProducts,
    };

    this.http.post<SharedItemDTO>("/api/v1/shared-item", formData, this.HttpOptions).subscribe({
      next: () => {
        this.messageService.sendMessage('Shared Items have been created successfully!');
        this.router.navigate(["/shared-item"]);
      },
      error: (error) => {
        if (error.status == 500 || error.status == 405) {
          this.activateAlert(error.error.error);
        } else {
          this.activateAlert(error.error);
        }
      }
    });
  }

  get formArray(): AbstractControl {
    return this.formGroup.get('formArray');
  }

  get selectAllProducts(): boolean {
    const formArray = this.formGroup.get('formArray') as FormArray;
    const productNumberListGroup = formArray.controls[2] as FormGroup;

    return productNumberListGroup.get('selectAllProducts').value;
  }

  get odName(): string {
    const formArray = this.formGroup.get('formArray') as FormArray;
    const odNameGroup = formArray.at(0) as FormGroup;

    return odNameGroup.get('odName')?.value;
  }

  get HttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  }

  activateAlert(message: string) {
    this.snackBar.open(message, '', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['red-snackbar']
    });
  }

}
