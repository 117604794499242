<footer>
  <div>
    Copyright &copy; BASF SE {{year}}
    <span
      *ngIf="version"
      class="separator"
    >|</span>
    <span *ngIf="version"> Version: {{version}} </span>
    <span
      *ngIf="environment"
      class="separator"
    >|</span>
    <span *ngIf="environment"> Environment: {{environment}} </span>
    <span
      class="separator"
    >|</span>
    <a
      class="row-item"
      routerLink="/imprint"
    >Imprint</a>
  </div>

</footer>
