import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AAS} from "../shared/models/dtr/aas.dto";
import {Customer} from "../shared/models/digital-twin.dto";

@Component({
  selector: 'app-dtr-aas',
  templateUrl: './dtr-aas.component.html',
  styleUrls: ['./dtr-aas.component.css']
})
export class DtrAasComponent {
  form: FormGroup;
  breadcrumb: any;
  dataLoaded = false;
  digitalTwinPageTitle: string;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient,
  ) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      cxIdentification: {value: ''},
      cxGlobalAssetId: {value: ''},
      bpn: {value: ''},
      materialNumberBASF: {value: ''},
      batchNumber: {value: ''},
      digitalTwinType: {value: ''},
      customerList: this.formBuilder.array([]),
      submodelList: this.formBuilder.array([]),
    });
    this.route.params.subscribe(params => {
      const id = params['id'];
      if (id) {
        this.getInformation(id);
      }
    });
  }

  getInformation(id: string) {
    this.http.get<AAS>(`/api/v1/dtr/${id}`).subscribe(data => {
      const transformedDigitalTwinType = data.specificAssetIds.find(x => x.name === 'digitalTwinType')?.value === 'PartInstance' ? 'Part Instance' :
        data.specificAssetIds.find(x => x.name === 'digitalTwinType')?.value === 'PartType' ? 'Part Type' : 'Unknown';
      const customerList: Customer[] = data.specificAssetIds
        .filter(id => id.name === 'customerPartId' && id.externalSubjectId?.keys)
        .map(id => {
          const customerBpnKey = id.externalSubjectId.keys.find(key => key.type === 'GlobalReference');
          return {
            bilateralContract: '',
            customerName: '',
            customerMaterialNumber: id.value,
            customerBpn: customerBpnKey ? customerBpnKey.value : ''
          };
        });
      const customerFormGroups = customerList.map(customer => {
        return this.formBuilder.group({
          customerMaterialNumber: [customer.customerMaterialNumber],
          customerBpn: [customer.customerBpn]
        });
      });
      const submodelFormGroups = data.submodelDescriptors.map(submodel => {
        const semanticIdValue = submodel.semanticId.keys.find(key => key.type === 'GlobalReference')?.value;
        const valueParts = semanticIdValue ? semanticIdValue.split('#') : [];
        let valuePart = valueParts.length > 1 ? valueParts[1] : '';
        if (valuePart === 'Pcf') {
          valuePart = valuePart.toUpperCase();
        }
        return this.formBuilder.group({
          valuePart: [valuePart]
        });
      });
      this.form.patchValue({
        cxIdentification: id,
        cxGlobalAssetId: data.globalAssetId,
        bpn: data.specificAssetIds.find(x => x.name === 'manufacturerId')?.value,
        materialNumberBASF: data.specificAssetIds.find(x => x.name === 'manufacturerPartId')?.value,
        digitalTwinType: transformedDigitalTwinType,
        batchNumber: data.specificAssetIds.find(x => x.name === 'batchId')?.value,
      });
      this.form.setControl('customerList', this.formBuilder.array(customerFormGroups));
      this.form.setControl('submodelList', this.formBuilder.array(submodelFormGroups));
      this.setBreadcrumb();
      this.dataLoaded = true;
    });
  }

  get customerList(): FormArray {
    return this.form.get('customerList') as FormArray;
  }

  get submodelList(): FormArray {
    return this.form.get('submodelList') as FormArray;
  }

  setBreadcrumb() {
    this.digitalTwinPageTitle = 'View Digital Twin';
    this.breadcrumb = [
      {title: 'Digital Twin Registry', href: '/dtr-catalog'},
      {title: 'View Digital Twin', href: ''}
    ];
  }

}
