import { Component, Input } from '@angular/core';
import {HelpersService} from "../services/helpers.service";

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.css']
})
export class CustomTooltipComponent {
  @Input() item: any;
  @Input() tooltipType: string;
  isVisible: boolean = false;

  show() {
    this.isVisible = true;
  }

  hide() {
    this.isVisible = false;
  }

  getFormattedCustomerBPNList(customerBPNList: string[] | string): string {
    if (typeof customerBPNList === 'string') {
      customerBPNList = customerBPNList.split(','); // Splitting by comma if it's a string
    }
    if (customerBPNList.length > 1) {
      return `${customerBPNList[0]}, ...`;
    } else {
      return customerBPNList.join(', ');
    }
  }

  protected readonly HelpersService = HelpersService;
}
