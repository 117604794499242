<app-card-layout [title]="'Create Shared Items'" [breadcrumb]="breadcrumb">

  <div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 40px;">
    <form [formGroup]="formGroup">
      <mat-stepper linear #stepper formArrayName="formArray">

        <!-- Step 0 -->
        <mat-step formGroupName="0" [stepControl]="formArray.get([0])">
          <h4>Operating Division</h4>
          <p>
            This configuration applies to the chosen operating division. Make sure that you are authorized for this
            operating division.
          </p>
          <mat-form-field appearance="outline" class="width-48" floatLabel="always">
            <mat-select formControlName="odName" placeholder="Operating Division"
              (selectionChange)="onSelectOD($event.value)">
              <mat-option *ngFor="let item of odList" [value]="item.name" [disabled]="item.disabled">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="m-t-20px">
            <button mat-raised-button color="primary" (click)="goBack(stepper)" type="button" class="m-r-10px">
              < Previous </button>
                <button mat-raised-button color="primary" (click)="goForward(stepper)" type="button"> Next
                  >
                </button>
          </div>
        </mat-step>

        <!-- Step 1 -->
        <mat-step formGroupName="1">
          <h4>Customers</h4>

          <p>
            Please enter here the selected customer ids with whom you want to share information. You can enter any
            customer id you want. The data is only shared if the customer is a sold to customer, gets a
            delivery of this product and is known in the network.
          </p>

          <mat-form-field appearance="outline" class="width-48" floatLabel="always">
            <mat-label>Add Customer</mat-label>
            <mat-chip-grid #chipList1 aria-label="Customer Selection">
              <mat-chip-row *ngFor="let partner of selectedPartnerList" (removed)="removePartner(partner)">
                {{ partner._id.partnerName ? partner._id.partnerName : partner._id.partnerID }}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            </mat-chip-grid>
            <input placeholder="{{partnerPlaceholder}}" #partnerInput
              [formControl]="partnerNameControl" [matChipInputFor]="chipList1" [matAutocomplete]="auto"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addManualPartner($event)" />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addPartner($event)">
              @for (option of filteredPartnerListObservable | async; track option) {
              <mat-option [value]="option">{{ option._id.partnerName }}</mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>

          <div class="m-t-20px">
            <button mat-raised-button color="primary" (click)="goBack(stepper);" type="button" class="m-r-10px">
              < Previous </button>
                <button mat-raised-button color="primary" (click)="goForward(stepper);" type="button">Next ></button>
          </div>
        </mat-step>

        <!-- Step 2 -->
        <mat-step formGroupName="2" [stepControl]="formArray.get([2])">
          <h4>Products</h4>
          <p>
            Please decide if you want to share information to products that are delivered into the network or if you
            want
            to select products. If you decide to select products please make sure that this list is always updated.
          </p>

          <mat-radio-group aria-label="Select an option" formControlName="selectAllProducts">
            <mat-radio-button [value]="true" class="width-48">All products
            </mat-radio-button>
            <br>
            <mat-radio-button [value]="false" class="width-48">Select products
            </mat-radio-button>
          </mat-radio-group>

          <div>
            <p>
              Please enter here the selected product numbers that you want to share. You can enter any product number
              you
              want. The data is only shared if the product is delivered to a customer in the network and if the product
              number is found on the delivery document.
            </p>

            <mat-form-field *ngIf="!selectAllProducts" appearance="outline" class="width-48" floatLabel="always">
              <mat-label>Product List</mat-label>
              <mat-chip-grid #chipList aria-label="Product selection">
                <mat-chip-row *ngFor="let material of selectedMaterialList" (removed)="removeMaterial(material)">
                  {{ material._id.materialName ? material._id.materialName : material._id.materialNumber }}
                  <button matChipRemove
                    [attr.aria-label]="'remove ' + (material._id.materialName ? material._id.materialName : material._id.materialNumber)">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
              </mat-chip-grid>
              <input placeholder="Select product or add a new product number..." #materialInput
                [formControl]="materialNameControl" [matChipInputFor]="chipList" [matAutocomplete]="auto"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addManualMaterial($event)" />
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnMaterial"
                (optionSelected)="addMaterial($event)">
                @for (option of filteredMaterialListObservable | async; track option) {
                <mat-option [value]="option">{{ option._id.materialName }}</mat-option>
                }
              </mat-autocomplete>
            </mat-form-field>

          </div>
          <div class="m-t-20px">
            <button mat-raised-button color="primary" (click)="goBack(stepper);" type="button" class="m-r-10px">
              < Previous </button>
                <button mat-raised-button color="primary" (click)="submit()">Create Shared Items</button>
          </div>
        </mat-step>
      </mat-stepper>
    </form>
  </div>
</app-card-layout>