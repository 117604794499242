import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card-layout',
  templateUrl: './card-layout.component.html',
  styleUrls: ['./card-layout.component.css']
})
export class CardLayoutComponent {
  @Input() title: string;
  @Input() breadcrumb: any;
  @Input() buttonText: string;
  @Input() buttonRoute: string | (() => void);

  constructor(private router: Router) {}

  onButtonClick(): void {
    if(typeof this.buttonRoute === 'function') {
      this.buttonRoute();
    } else {
      this.router.navigate([this.buttonRoute]);
    }
  }
}
