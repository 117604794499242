<div style="grid-area: header;">
  <header>
    <div class="content">
      <div class="app-name">
        <span routerLink="/landing" routerLinkActive="router-link-active">{{ env.appName }}</span>
      </div>

      <div class="icons">
        <div *ngIf="isUserLogged; then loggedIn else loggedOut"></div>

        <ng-template #loggedIn>
          <div>
            <mat-icon class="verticalSub" fontIcon="person" matTooltip="{{loggedFullName}}"></mat-icon>
          </div>

          <div class="cursor-pointer" routerLink="/landing">
            <mat-icon class="verticalSub" fontIcon="info" matTooltip="Information"></mat-icon>
          </div>

          <div class="cursor-pointer" routerLink="/logout" matTooltip="Logout">
            <mat-icon class="verticalSub" fontIcon="logout"></mat-icon>
          </div>
        </ng-template>

        <ng-template #loggedOut>
          <button mat-button class="login" routerLink="/login">
            Login
            <mat-icon iconPositionEnd fontIcon="login"></mat-icon>
          </button>
        </ng-template>
      </div>

    </div>
    <div class="badge">
      <div>
        <img src="../assets/images/basf-logo.svg"
          alt="BASF company logo">
      </div>
    </div>
  </header>
</div>