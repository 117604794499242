import {Component} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {MatDialog} from '@angular/material/dialog';
import {Clipboard} from '@angular/cdk/clipboard';
import {ContractNegotiation} from "../shared/models/contract-negotiation";

@Component({
  selector: 'app-edc-contracts',
  templateUrl: './edc-contracts.component.html',
  styleUrls: ['./edc-contracts.component.css']
})
export class EdcContractsComponent {
  dataSource = new MatTableDataSource<ContractNegotiation>();
  dataLoaded = false;
  displayedColumns: string[] = ['id', 'type', 'state', 'counterPartyId', 'counterPartyAddress', 'createdAt'];

  showFirstLastButtons = true;

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private clipboard: Clipboard) {
    this.getContractNegotiations();
  }

  getContractNegotiations() {
    this.http.get<any>("/api/v1/edc/contractnegotiations", {headers: {'Content-Type': 'application/json'}})
      .pipe(map(res => res.map((x: any) => ({
        id: x["@id"],
        type: x["edc:type"],
        state: x["edc:state"],
        counterPartyId: x["edc:counterPartyId"],
        counterPartyAddress: x["edc:counterPartyAddress"],
        createdAt: new Date(x["edc:createdAt"]).toLocaleString("es-ES")
      })))).subscribe(
      res => {
        this.dataSource.data = res;
        this.dataLoaded = true;
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.replace(/^0+/, '');

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
