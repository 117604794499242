
const ADMIN_ACTIONS = [
  'activate-deactivate-shared-item',
];

const OD_ACTIONS = [
  'activate-deactivate-shared-item',
];


export const GROUPS_PERMITTED_ACTIONS = {
  CFDS_MATPASS_ADMIN: ADMIN_ACTIONS,
  MATERIAL_PASS_DEVELOPER: ADMIN_ACTIONS,
  MATERIAL_PASS_OD1: OD_ACTIONS,
};
