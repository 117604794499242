<ng-container *ngIf="shouldUseFullLayout; else blankLayout">
  <div class="skeleton-grid">
    <mat-sidenav-container>
      <mat-sidenav-content>
        <app-header [isUserLogged]="isUserLogged"></app-header>
        <app-top-menu [isUserLogged]="isUserLogged"></app-top-menu>

        <main class="main-content" [ngClass]="{'main-content-logged': isUserLogged}" >
          <router-outlet></router-outlet>
        </main>

        <app-footer></app-footer>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</ng-container>

<ng-template #blankLayout>
  <router-outlet></router-outlet>
</ng-template>