<app-card-layout [title]="'Digital Twin Registry'" [breadcrumb]="breadcrumb">
  <div class="vertical-align-center">
    <div class="digital-twin-filter-box">
      <mat-icon color="primary" class="filter-icon">filter_alt
      </mat-icon>
      <mat-form-field appearance="outline">
        <mat-label>Search BASF Material Number</mat-label>
        <input matInput [(ngModel)]="searchTerm" (input)="applySearch()">
      </mat-form-field>
      <select-component
        [states]="[{displayValue: 'All', value: 'All'}, {displayValue: 'PartInstance', value: 'PartInstance'}, {displayValue: 'PartType', value: 'PartType'}]"
        [(state)]="filterType" (stateChange)="onTypeChange($event)" label="Digital Twin Type"/>
    </div>
  </div>


  <div class="card-list">
    <div *ngFor="let item of pagedItems" class="card-item">
      <div class="card-header" (mouseenter)="tooltip.show()" (mouseleave)="tooltip.hide()">
        <div class="card-title">{{ item.materialNumberBASF }}
        </div>
        <app-custom-tooltip #tooltip [item]="item" tooltipType="digitalTwin"></app-custom-tooltip>
      </div>

      <div class="card-content">
        <p *ngIf="item.digitalTwinType == 'PartInstance'">Batch No.: {{ item.batchNumber }}</p>
        <p>Customer Material No.: {{ HelpersService.getTrimmedMaterialNumber(item.customerNumberList) }}</p>
      </div>
      <div class="card-footer">
        <div class="margin-left-auto">
          <button mat-icon-button [routerLink]="['/dtr-aas/', item.id]" matTooltip="View">
            <mat-icon color="primary">visibility</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-progress-spinner [dataLoaded]="dataLoaded"></app-progress-spinner>
  <mat-paginator [pageSizeOptions]="[15, 20, 25]" (page)="pageChanged()"></mat-paginator>
</app-card-layout>
