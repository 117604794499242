<app-card-layout [title]="'Asset Data'" [breadcrumb]="breadcrumb">

  <form [formGroup]='form' (ngSubmit)="getAssetData()">
    <div>
      <mat-form-field appearance="outline" class="width-20 m-r-2">
        <mat-label>Method</mat-label>
        <mat-select formControlName="method">
          <mat-option *ngFor="let method of enabledMethods" [value]="method.value">
            {{ method.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="width-40">
        <mat-label>Asset Id</mat-label>
        <input formControlName="assetId" matInput>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Endpoint</mat-label>
        <input matInput formControlName="endpoint">
      </mat-form-field>
    </div>

    <div>
      <mat-form-field *ngIf="showBodyField" appearance="outline" class="full-width">
        <mat-label>Body</mat-label>
        <textarea matInput formControlName="body" rows="3"></textarea>
      </mat-form-field>
    </div>

    <button mat-raised-button color="primary" type="submit" style="width: 62%">Execute</button>
  </form>
</app-card-layout>

<br>

<app-card-layout [title]="'Asset Data JSON response:'" [breadcrumb]="null">
  <div class="pre-container">
    <button mat-icon-button *ngIf="resultLoaded" (click)="copyToClipboard()" aria-label="Copy to clipboard"
            class="copy-button">
      <mat-icon>content_copy</mat-icon>
    </button>
    <pre id="pre">{{ assetDataResult }}</pre>
  </div>
</app-card-layout>
