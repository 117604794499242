export const environment = {
  production: true,
  federationServiceUrl: 'https://federation-qa.basf.com/nidp/',
  federationService: true,
  backendUrl: 'https://test.cofinity-x.materialpassport.basf.com/',
  edcProtocolUrl: 'https://basf-preprod-001.prod-sovity.azure.sovity.io/control/api/v1/dsp',
  clientId: '69d7f048-c8d6-4e22-aebc-e99613023615',
  redirectUri: 'https://test.cofinity-x.materialpassport.basf.com',
  scope: 'openid CFDS',
  resourceServer: 'CFDS_COFTEST',
  responseType: 'code',
  appName: 'BASF Material Passport',
  shortName: 'Material Passport',
  mailTo: '',
  version: '1.0.0',
  envName: 'COFTEST'
};
