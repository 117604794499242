<div class="margin-top-20 vertical-align-center">
  <mat-form-field appearance="outline" class="search-bar-width">
    <mat-label>Search</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Search in columns" #input>
  </mat-form-field>
</div>

<ng-content></ng-content>

<mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="10" [showFirstLastButtons]="showFirstLastButtons"
  aria-label="Select page">
</mat-paginator>

<app-progress-spinner [dataLoaded]="dataLoaded"></app-progress-spinner>
