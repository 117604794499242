<app-card-layout [title]="'Catalog'">

  <div class="margin-top-20">
    <div class="vertical-align-center">
      <mat-form-field appearance="outline" class="width-48">
        <mat-label>Connector Protocol URL</mat-label>
        <input matInput placeholder="Enter connector protocol URL" [(ngModel)]="searchUrl">
      </mat-form-field>

      <div class="align-right margin-bottom-20 margin-left-auto">
        <button mat-raised-button color="primary" (click)="searchCatalog()">
          <mat-icon>search</mat-icon>
          Search Catalog
        </button>
      </div>
    </div>

    <mat-form-field appearance="outline" class="asset-limit-width margin-right-20">
      <mat-label>Assets Number</mat-label>
      <input matInput placeholder="Enter connector protocol URL" [(ngModel)]="assetLimit" type="number">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Asset Type</mat-label>
      <mat-select [(ngModel)]="filterType">
        <mat-option value="">No Type</mat-option>
        <mat-option value="data.core.digitalTwinRegistry">Digital Twin Registry</mat-option>
        <mat-option value="cx-taxo:UniqueIdPushConnectToChildNotification">Connect To Child</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <app-table [dataSource]="dataSource" [dataLoaded]="dataLoaded">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="assetId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset ID</th>
        <td mat-cell *matCellDef="let element">
          {{ element.assetId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="isNegotiated" *ngIf="edcProtocolUrl !== searchUrl">
        <th mat-header-cell *matHeaderCellDef>Is Negotiated</th>
        <td mat-cell *matCellDef="let element">
          <div class="{{'isNegotiated-'+element.isNegotiated}}">{{ element.isNegotiated }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="contractPolicy">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="openAssetPopup(element.assetId)" matTooltip="View Asset">
            <mat-icon color="primary">visibility</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Negotiate Contract" (click)="negotiateContract(element)"
                  *ngIf="edcProtocolUrl !== searchUrl && !element.isNegotiated">
            <mat-icon color="primary">handshake</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Consume Asset"
                  *ngIf="element.isNegotiated && searchUrl !== edcProtocolUrl"
                  (click)="viewAssetData(element.assetId)">
            <mat-icon color="primary">swap_horizon</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </app-table>

</app-card-layout>
