<app-card-layout [title]="'Quality Notifications'">
  <app-table [dataSource]="dataSource" [dataLoaded]="dataLoaded">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="notificationId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by notificationId">
          Notification ID
        </th>
        <td mat-cell *matCellDef="let element" class="text-field"> {{ element.notificationId }}</td>
      </ng-container>

      <ng-container matColumnDef="senderBpn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by senderBpn"> Sender BPN</th>
        <td mat-cell *matCellDef="let element"> {{ element.senderBpn }}</td>
      </ng-container>

      <ng-container matColumnDef="sentDateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by sentDateTime">Date</th>
        <td mat-cell *matCellDef="let element" class="text-field"> {{ element.sentDateTime }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status"> Status</th>
        <td mat-cell *matCellDef="let element"> {{ element.status }}</td>
      </ng-container>

      <ng-container matColumnDef="severity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by severity">Severity</th>
        <td mat-cell *matCellDef="let element">
          <div class="{{ 'mpChip-' + element.severity.toLowerCase() }}">{{ element.severity }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button [disabled]="dialogOpen" (click)="openQualityNotificationPopup(element.notificationId)"
                  matTooltip="View">
            <mat-icon color="primary">visibility</mat-icon>
          </button>
          <button mat-icon-button [matMenuTriggerFor]="mainmenu" matTooltip="Menu">
            <mat-icon color="primary">more_vert</mat-icon>
          </button>
          <mat-menu #mainmenu="matMenu">
            <button mat-menu-item (click)="openDeleteConfirmationPopup(element.notificationId)">
              <mat-icon color="warn">delete</mat-icon>
              Delete
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </app-table>
</app-card-layout>

