<app-card-layout [title]="'Shared Items'">
  <app-table [dataSource]="dataSource" [dataLoaded]="dataLoaded">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="OD">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by operating division"> OD </th>
        <td mat-cell *matCellDef="let element"> {{ element.odName }} </td>
      </ng-container>

      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by customer name"> Customer Name </th>
        <td mat-cell *matCellDef="let element"> {{ element.customerName }} </td>
      </ng-container>

      <ng-container matColumnDef="customerNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by customer number"> Customer Number </th>
        <td mat-cell *matCellDef="let element"> {{ HelpersService.getTrimmedMaterialNumber(element.customerNumber) }} </td>
      </ng-container>

      <ng-container matColumnDef="productList">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by product list"> Product List </th>
        <td mat-cell *matCellDef="let element" class="product-overflow-hidden" matTooltip="{{element.productList}}"> {{ (element.allProductsSelected) ? 'All products' : element.productList }} </td>
      </ng-container>

      <ng-container matColumnDef="sharingStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by sharing status"> Sharing Status </th>
        <td mat-cell *matCellDef="let element">
          <div class="{{'isRegistered-'+element.sharingStatus}}">
            {{ (element.sharingStatus) ? 'Active' : 'Inactive' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="options-align-right">
          <button *ngIf="!element.allProductsSelected" mat-icon-button [routerLink]="['/shared-product/', element.id]" matTooltip="View Products">
            <mat-icon color="primary">visibility</mat-icon>
          </button>

          <button mat-icon-button [matMenuTriggerFor]="mainmenu" matTooltip="Menu">
            <mat-icon color="primary">more_vert</mat-icon>
          </button>
          <mat-menu #mainmenu="matMenu">
            <div *ngIf="isPermittedAction('activate-deactivate-shared-item')">
              <button mat-menu-item (click)="openConfirmationPopup(element)"
                *ngIf="!element.sharingStatus; else deactivateButton">
                <mat-icon color="primary">play_circle_filled</mat-icon>
                Activate
              </button>
              <ng-template #deactivateButton>
                <button mat-menu-item (click)="openConfirmationPopup(element)">
                  <mat-icon color="primary">pause_circle_filled</mat-icon>
                  Deactivate
                </button>
              </ng-template>
            </div>

            <button mat-menu-item (click)="openChangeConfigurationConfirmationPopup(element)">
              <mat-icon color="primary">loop</mat-icon>
              Change configuation
            </button>

            <button mat-menu-item (click)="showComponentHistory(element)">
              <mat-icon color="primary">history</mat-icon>
              History
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </app-table>
</app-card-layout>
