<app-card-layout [title]="'Lookup Data Model'" [breadcrumb]="breadcrumb">

  <form [formGroup]='form' (ngSubmit)="getAspectModel()">
    <div>
      <mat-form-field appearance="outline" class="width-30 m-r-2">
        <mat-label>Aspect Model</mat-label>
        <mat-select formControlName="aspectModel" matInput>
          <mat-option *ngFor="let aspectModel of aspectModels" [value]="aspectModel.value">
            {{ aspectModel.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="width-30">
        <mat-label>Digital Twin Id</mat-label>
        <input formControlName="digitalTwinId" matInput>
      </mat-form-field>
    </div>

    <button mat-raised-button color="primary" type="submit" style="width: 62%">Execute</button>
  </form>
</app-card-layout>

<br>

<app-card-layout [title]="'Aspect model JSON result:'" [breadcrumb]="null">
  <pre id="pre">{{ aspectModelResult }}</pre>
</app-card-layout>
