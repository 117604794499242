import { HttpClient } from "@angular/common/http";
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AssetDataService } from "../edc-asset-data/edc-asset-data.service";
import { ConfirmPopupComponent } from "../shared/confirm-popup/confirm-popup.component";
import { Asset } from "../shared/models/asset";

@Component({
  selector: 'app-edc-catalog',
  templateUrl: './edc-catalog.component.html',
  styleUrls: ['./edc-catalog.component.css']
})
export class EdcCatalogComponent {
  dataSource = new MatTableDataSource<Asset>();
  dataLoaded = false;
  displayedColumns: string[] = ['assetId', 'contractPolicy'];
  httpOptions = { headers: { 'Content-Type': 'application/json' } };
  showFirstLastButtons = true;
  edcProtocolUrl = environment.edcProtocolUrl;
  searchUrl = this.edcProtocolUrl;
  filterType: string | null = null;
  assetLimit: number = 20;

  @ViewChild(MatTable) table!: MatTable<any>;

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private router: Router,
    private assetDataService: AssetDataService,
  ) {
  }

  ngOnInit(): void {
    const storedSearchUrl = sessionStorage.getItem('searchUrl');
    if (storedSearchUrl) {
      this.searchUrl = storedSearchUrl;
    }
    this.searchCatalog();
  }

  createCatalogRequestBody(counterPartyAddress: string) {
    const querySpec: any = {
      "@type": "edc:QuerySpec",
      "offset": 0,
      "limit": this.assetLimit,
      "sortField": "https://purl.org/dc/terms/type",
      "sortOrder": "ASC"
    };

    if (this.filterType) {
      querySpec.filterExpression = [{
        "operandLeft": "https://w3id.org/edc/v0.0.1/ns/type",
        "operator": "=",
        "operandRight": this.filterType
      }];
    }

    return {
      counterPartyAddress,
      querySpec: querySpec
    };
  }

  processCatalogResponse(res: any) {
    this.dataSource.data = res.map((x: any) => ({
      assetId: x.assetId,
      isNegotiated: x.isNegotiated,
      policyId: x.policyId,
      constraint: x.constraint,
      connectorAddress: x.connectorAddress,
      providerBPN: x.providerBPN,
      asset: x.asset
    }));
  }

  searchCatalog() {
    sessionStorage.setItem('searchUrl', this.searchUrl);
    const body = this.createCatalogRequestBody(this.searchUrl);
    this.adjustDisplayedColumns(this.searchUrl);
    this.dataLoaded = false;

    this.http.post<any>("/api/v1/edc/catalog", body, this.httpOptions)
      .pipe(map(res => this.processCatalogResponse(res)))
      .subscribe(() => {
        this.dataLoaded = true;
      });
  }

  negotiateContract(asset: Asset) {
    const catalogAssetDTO = {
      connectorAddress: asset.connectorAddress,
      providerBPN: asset.providerBPN,
      assetId: asset.assetId,
      policyId: asset.policyId,
      constraint: asset.constraint,
    };

    this.http.post<any>("/api/v1/edc/negotiatecontract", catalogAssetDTO, this.httpOptions)
      .subscribe({
        next: () => {
          window.location.reload();
        },
        error: (error) => {
          console.error("Error when negotiating contract", error);
        }
      });
  }

  adjustDisplayedColumns(counterPartyAddress: string) {
    this.displayedColumns = ['assetId', 'contractPolicy'];

    if (counterPartyAddress !== environment.edcProtocolUrl) {
      const contractPolicyIndex = this.displayedColumns.indexOf('contractPolicy');
      if (contractPolicyIndex !== -1) {
        this.displayedColumns.splice(contractPolicyIndex, 0, 'isNegotiated');
      } else {
        this.displayedColumns.push('isNegotiated');
      }
    }
  }

  openAssetPopup(assetId: string) {
    const asset = this.dataSource.data.find(x => x.assetId === assetId);

    if (asset) {
      let json: string = JSON.stringify(asset.asset, null, 2);

      this.dialog.open(ConfirmPopupComponent, {
        data: {
          dialogTitle: 'Asset',
          dialogContent: `<pre id="pre">${json}</pre>`,
          buttonText: 'Ok'
        },
        panelClass: 'custom-dialog-container',
        position: { top: '100px' },
        maxWidth: '90vw',
        width: '60vw'
      });
    } else {
      console.error('Asset not found');
    }
  }

  viewAssetData(assetId: string) {
    this.assetDataService.changeAssetId(assetId);
    this.router.navigate(['/edc-asset-data']).then(() => {
    }).catch(error => {
      console.error(error);
    });
  }
}
